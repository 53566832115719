import React from 'react';
import UserAvatar from '../UserAvatar/UserAvatar';
import DateView from '../DateView/DateView';
import { Text, Stack, Box } from '@chakra-ui/core';
import UserModal from '../UserModal/UserModal';
import EditCommentModal from '../../containers/EditCommentModal';

const Comment = ({ comment, bookId, review, userRecord, triggerRefresh }) => {
  return (
    <Box id={`c_${comment.id}`}>
      <UserModal userId={comment.userId}>
        <UserAvatar
          userAvatar={comment.userAvatar}
          userName={comment.userName}
          userLevel={comment.userLevel}
        />
      </UserModal>
      <Text fontSize="sm">{comment.content}</Text>
      <Stack isInline>
        {comment.updateDate ? (
          <Text fontSize="xs" style={{ float: 'left' }}>
            Edited on:&nbsp;
            <DateView
              style={{ float: 'right' }}
              size={'xs'}
              date={comment.updateDate}
            />
          </Text>
        ) : (
            <DateView size={'xs'} date={comment.createDate} />
          )}
        {comment.editReason ? (
          <Text fontSize="xs">Reason: {comment.editReason}</Text>
        ) : null}
      </Stack>
      <EditCommentModal
        commentData={comment}
        bookId={bookId}
        review={review}
        userRecord={userRecord}
        triggerRefresh={triggerRefresh}
      />
    </Box>
  );
};

export default Comment;
