import React, { useEffect, useState } from 'react';
import { API_URL } from '../../common/common.js';
import UploadImage from '../UploadImage/UploadImage';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import axios from 'axios';
import { Box, Grid, Button, Input, useToast } from '@chakra-ui/core';
import { useAuth } from '../../context/auth';

const UserProfile = ({ selfClose }) => {
  const { sessionAuth, setSessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [email, setEmail] = useState(sessionAuth.user.email);
  const [newAvatar, setNewAvatar] = useState(sessionAuth.user.avatar);
  const [avatar, setAvatar] = useState(sessionAuth.user.avatar);

  const toast = useToast();

  const update = (data) => {
    setLoading(true);
    axios
      .put(`${API_URL}/users/${sessionAuth.user.id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionAuth.token}`,
        },
      })
      .then((res) => {
        const newSessionAuth = { ...sessionAuth }
        newSessionAuth.user = res.data;
        toast({
          title: "Profile updated.",
          description: '',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setSessionAuth(newSessionAuth)
        setError(null);
        selfClose();
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  }

  const handleSubmit = () => {
    if (email === sessionAuth.user.email && avatar === newAvatar) {
      return selfClose();
    } else if (avatar !== newAvatar) {
      setTrigger(!trigger);
    } else if (email !== sessionAuth.user.email) {
      update({
        email,
        avatar,
      });
    }

  }

  useEffect(() => {
    if (!trigger) {
      return;
    }
    update({
      email,
      avatar,
    });
  }, [avatar]);

  const handleInputChange = (e, setter) => {
    const inputValue = e.target.value;
    setter(inputValue);
  };

  return (
    <>
      {(!loading) ? (error) && <ErrorAlert error={error} /> : null}
      <Box display="flex" alignItems="left" justifyContent="space-between">
        <Grid templateColumns="30% 30%" gap={6}>
          <Box>
            <UploadImage
              defaultImage={API_URL + '/images/' + avatar}
              type={'avatar'}
              trigger={trigger}
              setter={setAvatar}
              setterNew={setNewAvatar}
            ></UploadImage>
          </Box>
          <Box>
            <Input width="180px"
              placeholder="Email" marginTop="10px" value={email}
              onChange={(e) => handleInputChange(e, setEmail)}
            />
          </Box>
          <Button
            mr={3}
            _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
            bg="brand.black1"
            color="brand.white1"
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
          <Button
            mr={3}
            _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
            bg="brand.black1"
            color="brand.white1"
            onClick={selfClose}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default UserProfile;
