import React from 'react';
import {
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/core';

const ErrorAlert = (errorData) => {
  return (
    <>
      <Alert status="error">
        <AlertIcon />
        <AlertTitle mr={2}>Error: </AlertTitle>
        <AlertDescription> {errorData.error} </AlertDescription>
      </Alert></>
  );
};

export default ErrorAlert;
