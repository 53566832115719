import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import { API_URL } from '../../common/common.js';
import { useAuth } from '../../context/auth';
import { Flex, useToast, Button } from '@chakra-ui/core';
import ReviewModal from '../../containers/ReviewModal';
import BorrowAlertBox from '../BorrowAlertBox/BorrowAlertBox';
import { enumData } from '../../validator/validator';

const BookActions = ({
  bookId,
  userRecord,
  title,
  bookStatus,
  triggerRefresh,
  setBookMark,
  setOpenReviews,
}) => {
  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [possesion, setPossesion] = useState(false);
  const [status, setStatus] = useState(bookStatus);
  const [action, setAction] = useState('');

  const toast = useToast();
  useEffect(() => {
    if (status === 'Borrowed') {
      if (!userRecord.borrows) {
        if (userRecord.borrows.toDate === undefined) {
          setPossesion(false);
        }
      } else if (userRecord.borrows.toDate === null) {

        setPossesion(true);
      }
    }
  }, []);

  useEffect(() => {
    if (action) {
      action === 'Borrow' ? borrowBook() : returnBook();
    }
  }, [action]);

  const borrowBook = () => {
    setLoading(true);
    setError(null);
    axios
      .post(
        `${API_URL}/books/${bookId}/borrowing/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionAuth.token}`,
          },
        }
      )
      .then((res) => {
        toast({
          title: res.data.message,
          description: 'Please return this book after two weeks',
          status: 'success',
          duration: enumData.toastDuration,
          isClosable: true,
        });
        setStatus('Borrowed');
        setPossesion(true);
        setBookMark('Borrowed');
        triggerRefresh();
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  };

  const returnBook = () => {
    setLoading(true);
    setError(null);
    axios
      .put(
        `${API_URL}/books/${bookId}/borrowing/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionAuth.token}`,
          },
        }
      )
      .then((res) => {
        setTimeout(() => {
          toast({
            title: res.data.message,
            description: 'Please leave rate and leave a review.',
            status: 'success',
            duration: enumData.toastDuration,
            isClosable: true,
          });
        }, 500);
        toast({
          description: '',
          title: 'You gain +10 points',
          duration: enumData.toastDuration,
          isClosable: true,
        });
        setStatus('Free');
        setBookMark('Free');
        setPossesion(false);
        triggerRefresh();
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  };

  const reversedStatus = status === 'Free' ? 'Borrow' : 'Return';

  return (
    (!userRecord.isLoading && sessionAuth.user.isBanned) ? status === "Borrowed" && possesion ? <BorrowAlertBox
      title={title}
      setAction={() => setAction('Return')}
      actionName={'Return'}
    /> : null
      : (
        !userRecord.isLoading && <Flex align="center" isInline>
          {!loading ? error && <ErrorAlert error={error} /> : null}
          {status === 'Free' || possesion ? (
            <BorrowAlertBox
              title={title}
              setAction={() => setAction(reversedStatus)}
              actionName={reversedStatus}
            />
          ) : (
              <Button
                mr="7px"
                isDisabled="true"
                bg="brand.black1"
                _hover={{ bg: 'brand.red1', color: 'brand.white1' }}
                color="brand.white1"
              >
                Borrowed
              </Button>
            )}
          {userRecord && userRecord.isRead ? (
            <ReviewModal
              userRecord={userRecord}
              triggerRefresh={triggerRefresh}
              setOpenReviews={setOpenReviews}
              bookId={bookId}
              title={title}
            />
          ) : null}
        </Flex>
      )
  )
};

export default BookActions;
