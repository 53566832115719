import React, { useEffect, useState } from "react";
import { API_URL } from "../common/common.js";
import validateParam from "../validator/validator.js";
import ErrorLabel from "../components/ErrorLabel/ErrorLabel";
import ErrorAlert from "../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../components/SuccessAlert/SuccessAlert";
import { NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/auth";
import {
  FormControl,
  FormLabel,
  Button,
  Input,
  Grid,
  Text,
  Tooltip,
} from "@chakra-ui/core";

const Credentials = (props) => {
  const currentLocation = props.location.pathname;
  const { setSessionAuth } = useAuth();
  const [registering, setRegistering] = useState(currentLocation === "/signup");
  const [username, setUsername] = useState("YourName");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("JqXsMWy5l75bzVb3");
  const [isRegistered, setIsRegistered] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [usernameErr, setUsernameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");

  const referer = props?.location?.state?.referer || "/";

  useEffect(() => {
    setRegistering(currentLocation === "/signup");
    setError(null);
    setLoading(false);
    setIsRegistered(false);
    setLoggedIn(false);
    setUsernameErr("");
    setEmailErr("");
    setPasswordErr("");
  }, [currentLocation]);

  const sendRegisterData = () => {
    alert("This feature has been disabled!");
  };

  const login = () => {
    const data = {
      username: username,
      password: password,
    };
    setLoading(true);
    setError(null);
    axios
      .post(`${API_URL}/session/`, data)
      .then((res) => {
        setSessionAuth(res.data);
        setLoggedIn(true);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(setLoading(false));
  };

  const loginRedirect = () => {
    return setTimeout(() => {
      login();
    }, 1000);
  };

  const validateInput = () => {
    const usernameError = validateParam("username", username);
    if (usernameError) {
      setUsernameErr(usernameError);
      return false;
    }
    const passwordError = validateParam("password", password);
    if (passwordError) {
      setPasswordErr(passwordError);
      return false;
    }
    if (email) {
      const emailError = validateParam("email", email);
      if (emailError) {
        setEmailErr(emailError);
        return false;
      }
    }
    return true;
  };

  const checkForEnterKey = (e) => {
    if (e.which === 13) {
      if (validateInput()) {
        registering ? sendRegisterData() : login();
      }
    }
  };

  return (
    <>
      {loggedIn && <Redirect to={referer} />}
      {isRegistered && registering && (
        <SuccessAlert message={"Account created, logging you in..."} loader />
      )}
      {!loading ? error && <ErrorAlert error={error} /> : null}
      <Grid
        width='300px'
        minWidth='200px'
        position='absolute'
        marginTop='40px'
        marginLeft='40px'
        gap={3}>
        <Text fontWeight='bold'>{registering ? "Register" : "Login"}</Text>
        <FormControl>
          <FormLabel isRequired>Username</FormLabel>
          <Input
            placeholder='Username'
            value='YourName'
            onChange={(e) => {
              setUsername(e.target.value);
              setUsernameErr("");
            }}
          />
          {usernameErr ? <ErrorLabel message={usernameErr} /> : null}
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            placeholder='Password'
            type='password'
            value='JqXsMWy5l75bzVb3'
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordErr("");
            }}
            onKeyPress={(e) => checkForEnterKey(e)}
          />
          {passwordErr ? <ErrorLabel message={passwordErr} /> : null}
        </FormControl>
        {registering && (
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder='example@mail.com'
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailErr("");
              }}
              onKeyPress={(e) => checkForEnterKey(e)}
            />
            {emailErr ? <ErrorLabel message={emailErr} /> : null}
          </FormControl>
        )}
        <Grid templateColumns='50% 50%'>
          <NavLink
            to={{
              pathname: registering ? "/login" : "/signup",
              state: { referer: referer },
            }}>
            <Tooltip
              hasArrow
              label={
                registering
                  ? "Click to proceed to login page"
                  : "Click to make a registration"
              }
              placement='bottom'
              zIndex='1000'>
              <Text fontSize='md'>
                {registering
                  ? "Already have an account?"
                  : "Don't have an account?"}
              </Text>
            </Tooltip>
          </NavLink>
          <Button
            color='brand.white1'
            bg='brand.green1'
            mr={3}
            onClick={() => {
              if (validateInput()) registering ? sendRegisterData() : login();
            }}>
            {registering ? "Sign up" : "Log in"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Credentials;
