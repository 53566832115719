import React from 'react';
import { Select, Spinner } from '@chakra-ui/core';
import ErrorAlert from '../ErrorAlert/ErrorAlert';

const SelectLevel = ({ enums, onChange, value }) => {
    return (
        <Select
            color="black"
            placeholder={"Level..."}
            value={value}
            variant="flushed"
            name="level"
            onChange={onChange}
        >
            {enums.isLoading ? (
                <Spinner />
            ) : enums.error ? (
                <ErrorAlert />
            ) : (
                        enums.data.levels &&
                        enums.data.levels.map((item) => (
                            <option value={item.id} key={item.id}>
                                {item.name}
                            </option>
                        ))
                    )}
        </Select>
    )
}
export default SelectLevel;