import React from "react";
import ReactStars from "react-rating-stars-component";
import { Tooltip } from '@chakra-ui/core'

const RatingStars = ({ rating, isHalf, edit, onChange }) => {
  const settings = {
    size: 25,
    count: 5,
    color: "#b3b4b5",
    activeColor: "#ffb700",
    value: rating,
    isHalf: isHalf,
    edit: edit
  };

  return (
    <Tooltip hasArrow label={rating?.toFixed(2) || "No rating"} placement="bottom">
      <div style={{ maxWidth: '110px' }}>
        <ReactStars onChange={onChange} {...settings} style={{ float: 'left' }} />
      </div>
    </Tooltip>
  )
}

export default RatingStars;
