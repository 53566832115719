import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../common/common';
import {
  Button,
  Tooltip
} from '@chakra-ui/core';
import { useAuth } from '../../context/auth';
import Like from '../Like/Like';
import Loader from '../Loader/Loader';
import ErrorAlert from '../ErrorAlert/ErrorAlert';

const LikeReview = ({ likesCount, bookId, reviewId, userLikes }) => {
  const [count, setLikesCount] = useState(likesCount);
  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLiked, setIsLiked] = useState(
    !!userLikes.find((like) => like.reviewId === reviewId)
  );

  const createLike = () => {
    axios
      .post(
        `${API_URL}/books/${bookId}/reviews/${reviewId}/like`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionAuth.token}`,
          },
        }
      )
      .then((res) => {
        setIsLiked(true);
        setLikesCount(count + 1);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(setLoading(false));
  };
  const deleteLike = () => {
    axios
      .delete(`${API_URL}/books/${bookId}/reviews/${reviewId}/like`, {
        headers: {
          Authorization: `Bearer ${sessionAuth.token}`,
        },
      })
      .then((res) => {
        setIsLiked(false);
        setLikesCount(count - 1);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(setLoading(false));
  };

  return loading ? (
    <Loader />
  ) : (
      <>
        {error ? <ErrorAlert error={error} /> : null}
        <Tooltip hasArrow label={isLiked ? 'Unlike' : 'Like'} placement="bottom">
          <Button
            onClick={() => (isLiked ? deleteLike() : createLike())}
            _focus={{ border: 'none', outline: 'none' }}
            style={{
              filter: isLiked ? 'grayScale(0%)' : 'grayScale(100%)',
              padding: '0.5%',
              float: 'right',
              backgroundColor: 'transparent',
            }}
          >
            <Like likes={count} />
          </Button>
        </Tooltip>
      </>
    );
};

export default LikeReview;
