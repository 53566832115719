import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { API_URL } from '../../common/common.js';
import UploadImage from '../UploadImage/UploadImage';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import axios from 'axios';
import useEnums from '../../hooks/use-enums.js';
import validateParam, { enumData } from '../../validator/validator';
import { Box, Grid, Button, Input, useToast } from '@chakra-ui/core';
import { useAuth } from '../../context/auth';
import ErrorLabel from '../ErrorLabel/ErrorLabel';

const schema = { username: true, email: true, password: true };
const CreateUser = withRouter(({ history }) => {
  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(() => schema);
  const [trigger, setTrigger] = useState(false);
  const toast = useToast();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(0);
  const [avatar, setAvatar] = useState('');

  const enums = useEnums();

  const refreshPage = () => {
    history.replace({
      pathname: '/empty',
    });
    setTimeout(() => {
      history.replace(
        {
          pathname: '/admin/users/create',
        },
        0
      );
    });
  };

  useEffect(() => {
    
  }, [avatar]);

  const handleInputChange = (e, setter) => {
    const inputValue = e.target.value;
    const name = e.target.name;
    const error = validateParam(name, inputValue);
    const errorObj = { ...validationError };
    setter(inputValue);
    if (error) {
      errorObj[name] = error;
      return setValidationError(errorObj);
    } else {
      errorObj[name] = null;
      return setValidationError(errorObj);
    }
  };
  const isDisabled = ![validationError.password, validationError.username].every((v) => !v);
  return (
    <>
      {!loading || !enums.isLoading
        ? (error || enums.error) && <ErrorAlert error={error || enums.error} />
        : null}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Grid margin="3%" templateColumns="70% 30%" width="600px" gap={6}>
          <Box p={5} shadow="md" borderWidth="1px">
            <Input
              name="username"
              placeholder="username...<required>"
              value={username}
              onChange={(e) => handleInputChange(e, setUsername)}
            />
            {validationError['username'] ? (
              <ErrorLabel message={validationError['username']}></ErrorLabel>
            ) : (
                null
              )}
            <Input
              name="password"
              placeholder="password...<required>"
              onChange={(e) => handleInputChange(e, setPassword)}
            />
            {validationError['password'] ? (
              <ErrorLabel message={validationError['password']}></ErrorLabel>
            ) : (
                null
              )}
            <Input
              name="email"
              placeholder="email..."
              onChange={(e) => handleInputChange(e, setEmail)}
            />
            {validationError['email'] ? (
              <ErrorLabel message={validationError['email']}></ErrorLabel>
            ) : (
                null
              )}
          </Box>
          <Box p={5}>
            <UploadImage
              defaultImage={`${API_URL}/images/default_avatar.jpg`}
              type={'avatar'}
              trigger={trigger}
              setter={setAvatar}
            ></UploadImage>
          </Box>
          <Button
            _hover={{ bg: isDisabled ? 'brand.red1' : 'brand.green2', color: isDisabled ? 'brand.white1' : 'brand.black1' }}
            bg="brand.black1"
            color="brand.white1"
            onClick={() => alert('This feature has been disabled!')}
            disabled={isDisabled}
            maxWidth="100px"
          >
            Submit
              </Button>
        </Grid>
      </Box>
    </>
  );
})

export default CreateUser;


