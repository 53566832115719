
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";

function PrivateRoute({ component: Component, admin, ...rest }) {
  const { sessionAuth } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        sessionAuth ? (
          !sessionAuth.user.isAdmin && admin ? (
            <Redirect to="/" />
          ) : (
              <Component {...props} />
            )
        ) : (
            <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />
          )
      }
    />
  );
}

export default PrivateRoute;