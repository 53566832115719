import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ErrorAlert from '../components/ErrorAlert/ErrorAlert';
import { API_URL } from '../common/common.js';
import { useAuth } from '../context/auth';
import validateParam, { enumData } from '../validator/validator';
import {
  Button,
  Input,
  Modal,
  useDisclosure,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Textarea,
  useToast,
  ModalCloseButton,
} from '@chakra-ui/core';
import ErrorLabel from '../components/ErrorLabel/ErrorLabel';

const EditCommentModal = ({
  bookId,
  userRecord,
  review,
  commentData,
  triggerRefresh,
}) => {
  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [comment, setComment] = useState('');
  const [editReason, setEditReason] = useState(null);
  const [errorClient, setErrorClient] = useState(null);

  const toast = useToast();

  const initialRef = React.useRef();
  const reviewId = review.id;

  const userComment = userRecord.comments?.find(
    (rev) =>
      rev.id === commentData.id && commentData.userId === sessionAuth.user.id
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (userComment) {
      setComment(userComment.content);
      setEditReason(null);
    }
  }, [isOpen, userComment]);

  const editComment = (id, comment, editReason) => {
    setLoading(true);
    setError(null);
    axios
      .put(
        `${API_URL}/books/${bookId}/reviews/${reviewId}/comments/${id}`,
        {
          content: comment,
          editReason: editReason,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionAuth.token}`,
          },
        }
      )
      .then(() => triggerRefresh())
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  };

  const deleteComment = () => {
    setLoading(true);
    setError(null);
    axios
      .delete(
        `${API_URL}/books/${bookId}/reviews/${reviewId}/comments/${userComment.id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionAuth.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.message) {
          toast({
            title: 'Comment is deleted.',
            description: '',
            status: 'success',
            duration: enumData.toastDuration,
            isClosable: true,
          });

          setComment('');
          setEditReason(null);
          triggerRefresh();
        }
      })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateComment = (comment, editReason) => {
    if (userComment.content === comment) {
      return onClose();
    }
    const validatedComment = validateParam('comment', comment);
    if (validatedComment) {
      return setErrorClient(validatedComment);
    } else {
      editComment(userComment.id, comment, editReason);
      setErrorClient(null)
    }
  };

  return (!userRecord.isLoading && userComment && !sessionAuth.user.isBanned ? <>
    <Button
      style={{ cursor: 'pointer' }}
      _hover={{ color: 'brand.green1', bg: 'transparent' }}
      variantColor="brand.green1"
      variant="ghost"
      bg="transparent"
      onClick={onOpen}
    >
      Edit Comment
      </Button>
    <Modal
      initialFocusRef={initialRef}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Comment</ModalHeader>
        <ModalBody>
          <ModalCloseButton
            bg="brand.red1"
            color="brand.white1"
            _hover={{ bg: 'brand.black1', color: 'brand.white1' }}
          />
          {!loading ? error && <ErrorAlert error={error} /> : null}
          {!loading ? errorClient && <ErrorLabel message={errorClient} /> : null}
          <Textarea
            ref={initialRef}
            marginTop="2%"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></Textarea>
        </ModalBody>
        <ModalFooter>
          {!loading && (
            <>
              <Button
                marginRight="1%"
                _hover={{ bg: 'brand.red1', color: 'brand.white' }}
                bg="brand.black1"
                color="brand.white1"
                onClick={() => deleteComment()}
              >
                Delete
                </Button>
              <Input
                marginRight="1%"
                onChange={(e) => setEditReason(e.target.value)}
                placeholder="Edit reason..."
              ></Input>
              <Button
                marginRight="1%"
                _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                bg="brand.black1"
                color="brand.white1"
                onClick={() => updateComment(comment, editReason)}
              >
                Update
                </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  </> : null);
};

export default EditCommentModal;
