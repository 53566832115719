import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { API_URL } from '../../common/common.js';
import UploadImage from '../UploadImage/UploadImage';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import SelectGenre from '../SelectGenre/SelectGenre';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import axios from 'axios';
import useEnums from '../../hooks/use-enums.js';
import validateParam, { enumData } from '../../validator/validator';
import { Textarea, Box, Grid, Button, Input, useToast } from '@chakra-ui/core';
import { useAuth } from '../../context/auth';
import ErrorLabel from '../ErrorLabel/ErrorLabel';

const schema = { title: true, author: true, year: true, description: true };

const CreateBook = withRouter(({ book, history }) => {

  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(() => schema);
  const [trigger, setTrigger] = useState(false);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [year, setYear] = useState(0);
  const [description, setDescription] = useState('');
  const [languageId, setLanguage] = useState('');
  const [genreId, setGenre] = useState('');
  const [cover, setCover] = useState('');
  const [isCoverSelected, setIsCoverSelected] = useState(false);

  const toast = useToast();
  const enums = useEnums();

  const refreshPage = () => {
    history.replace({
      pathname: '/empty',
    });
    setTimeout(() => {
      history.replace(
        {
          pathname: '/admin/books/create',
        },
        0
      );
    });
  };
  useEffect(() => {
    
  }, [cover]);

  const handleInputChange = (e, setter) => {
    const inputValue = e.target.value;
    const name = e.target.name;
    const error = validateParam(name, inputValue);
    const errorObj = { ...validationError };
    setter(inputValue);
    if (error) {
      errorObj[name] = error;
      return setValidationError(errorObj);
    } else {
      errorObj[name] = null;
      return setValidationError(errorObj);
    }
  };
  const isDisabled = !Object.values(validationError).every((v) => !v);
  return (
    <>
      {!loading || !enums.isLoading
        ? (error || enums.error) && <ErrorAlert error={error || enums.error} />
        : null}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Grid margin="3%" templateColumns="70% 30%" width="600px" gap={6}>
          <Box p={5} shadow="md" borderWidth="1px">
            <Input
              name="title"
              placeholder="Title"
              value={title}
              onChange={(e) => handleInputChange(e, setTitle)}
            />
            {validationError['title'] ? (
              <ErrorLabel message={validationError['title']}></ErrorLabel>
            ) : (
                <div>&nbsp;</div>
              )}
            <Input
              name="author"
              placeholder="Author"
              onChange={(e) => handleInputChange(e, setAuthor)}
            />
            {validationError['author'] ? (
              <ErrorLabel message={validationError['author']}></ErrorLabel>
            ) : (
                <div>&nbsp;</div>
              )}
            <Input
              name="year"
              placeholder="Year"
              onChange={(e) => handleInputChange(e, setYear)}
            />
            {validationError['year'] ? (
              <ErrorLabel message={validationError['year']}></ErrorLabel>
            ) : (
                <div>&nbsp;</div>
              )}
            <Textarea
              name="description"
              onChange={(e) => handleInputChange(e, setDescription)}
              placeholder="Description"
              resize="none"
            />
            {validationError['description'] ? (
              <ErrorLabel message={validationError['description']}></ErrorLabel>
            ) : (
                <div>&nbsp;</div>
              )}
            <SelectLanguage
              enums={enums}
              value={languageId}
              onChange={(e) => handleInputChange(e, setLanguage)}
            />
            <SelectGenre
              enums={enums}
              value={genreId}
              onChange={(e) => handleInputChange(e, setGenre)}
            />
          </Box>
          <Box p={5}>
            {!isCoverSelected ? (
              <ErrorLabel message={'Please select a book cover'}></ErrorLabel>
            ) : (
                <div>&nbsp;</div>
              )}
            <UploadImage
              defaultImage={`${API_URL}/images/default_book.png`}
              type={'cover'}
              trigger={trigger}
              setter={setCover}
              setterNew={setIsCoverSelected}
            ></UploadImage>
          </Box>
          <Button
            _hover={{ bg: isDisabled ? 'brand.red1' : 'brand.green2', color: isDisabled ? 'brand.white1' : 'brand.black1' }}
            bg="brand.black1"
            color="brand.white1"
            maxWidth="100px"
            onClick={() => {
              alert('This feature has been disabled!');
            }}
            disabled={isDisabled || !isCoverSelected}
          >
            Submit
          </Button>
        </Grid>
      </Box>
    </>
  );
})

export default CreateBook;
