import React, { useState } from 'react';
import { API_URL } from '../../common/common.js';
import {
  Tooltip,
  Stack,
  Box,
  Tag,
  TagLabel,
  Icon,
  PseudoBox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/core';
import { NavLink, withRouter } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import axios from 'axios';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import UserModal from '../UserModal/UserModal';
import UserAvatar from '../UserAvatar/UserAvatar';
import BanUserModal from '../../containers/BanUserModal';

const UserListItem = withRouter(({ user, history }) => {
  const { sessionAuth } = useAuth();
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [banStatus, setBanStatus] = useState(user.isBanned);

  const toast = useToast();
  const openDeletePopup = () => setIsOpen(!isOpen);
  const closeDeletePopup = () => setIsOpen(false);
  const initialFocusRef = React.useRef();

  const refreshPage = () => {
    history.replace({
      pathname: '/empty',
    });
    setTimeout(() => {
      history.replace({
        pathname: '/admin/users',
      }, 0);
    });
  }

  const deleteUser = () => {    
    alert('This feature has been disabled!');
  };

  return (
    <PseudoBox _hover={{ bg: 'gray.200' }} roundedLeft="full">
      <Stack isInline width="100%">
        <Box className="BookItem-body" width="160px" isTruncated>
          <UserModal userId={user.id}>
            <UserAvatar
              userAvatar={user.avatar}
              userName={user.username}
              userLevel={user.level}
            />
          </UserModal>
        </Box>
        <Box marginTop="8px" width="440px" float="right">
          <Tag variantColor="gray" marginRight="5px">
            <Tooltip hasArrow label={`Email`} placement="bottom" zIndex="1000">
              <NavLink to='#'>
                <TagLabel fontSize="xs" className="book-language">
                  {user.email || 'No email'}
                </TagLabel>
              </NavLink>
            </Tooltip>
          </Tag>
          <Tag variantColor={user.isAdmin ? 'orange' : 'blue'} marginRight="5px">
            <Tooltip hasArrow label={`Search ${user.isAdmin ? 'Admin' : 'Reader'} users`} placement="bottom" zIndex="1000">
              <NavLink to={{ pathname: '/admin/users', queryString: `&isAdmin=${user.isAdmin}`, state: { admin: true } }}>
                <TagLabel fontSize="xs" className="book-genre">
                  {user.isAdmin ? 'Admin' : 'Reader'}
                </TagLabel>
              </NavLink>
            </Tooltip>
          </Tag>
          <Tag variantColor={banStatus ? 'red' : 'green'} >
            <Tooltip hasArrow label={`Search ${user.isAdmin ? 'Banned' : 'Active'} users`} placement="bottom" zIndex="1000">
              <NavLink to={{ pathname: '/admin/users', queryString: `&isBanned=${banStatus}`, state: { admin: true } }}>
                <TagLabel fontSize="xs" className="book-genre">
                  {banStatus ? 'Banned' : 'Active'}
                </TagLabel>
              </NavLink>
            </Tooltip>
          </Tag>
          <Tag variantColor='transparent'>
            <Tooltip hasArrow label={`Search ${user.level} users and up`} placement="bottom" zIndex="1000">
              <NavLink to={{ pathname: '/admin/users', queryString: `&levelId=${user.levelId}`, state: { admin: true } }}>
                <TagLabel fontSize="xs" className="book-genre">
                  {user.points}
                </TagLabel>
              </NavLink>
            </Tooltip>
          </Tag>
          <Tooltip hasArrow label={`Edit`} placement="bottom" zIndex="1000">
            <NavLink to={{ pathname: `/admin/users/${user.id}`, state: { user: user } }}>
              <Icon name='edit' marginRight="5px" />
            </NavLink>
          </Tooltip>
          <Tooltip hasArrow label={banStatus ? 'Unban' : `Ban`} placement="bottom" zIndex="1000">
            <NavLink to="#">
              <BanUserModal userId={user.id} username={user.username} banStatus={user.isBanned} updateBanTag={setBanStatus} />
            </NavLink>
          </Tooltip>
          <Popover
            initialFocusRef={initialFocusRef}
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={closeDeletePopup}
            placement="right"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Tooltip
                hasArrow
                label='Delete'
                placement="bottom"
                zIndex="1000"
              >
                <NavLink to="#">
                  <Icon
                    name="delete"
                    onClick={openDeletePopup}
                    zIndex='1000'
                  />

                </NavLink>
              </Tooltip>
            </PopoverTrigger>
            {error ? <ErrorAlert error={error} /> : null}
            <PopoverContent
              zIndex={4}
              width="240px"
              height="30px"
              marginLeft="100%"
              marginTop="-10%"
              bg="transparent"
              shadow="none"
              border="none"
            >
              <NavLink to="#">
                <PopoverBody fontSize="11px" color="brand.red1" fontWeight="Bold">
                  Delete this user?
                  <ButtonGroup size="xs" marginLeft="5%" verticalAlign>
                    <Button variant="outline" onClick={closeDeletePopup} ref={initialFocusRef} fontSize="10px" color="black">
                      Cancel
                    </Button>
                    <Button variantColor="red" onClick={deleteUser}>
                      Yes
                    </Button>
                  </ButtonGroup>
                </PopoverBody>
              </NavLink>
            </PopoverContent>
          </Popover>
        </Box>
      </Stack>
    </PseudoBox>
  );
})
export default UserListItem;

