import React, { useState, useEffect } from 'react';
import { API_URL } from '../common/common.js';
import Review from '../components/Review/Review';
import {
  Spinner,
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
  Grid,
  Box,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/core';
import axios from 'axios';
import { useAuth } from '../context/auth';

const Reviews = (props) => {
  const { sessionAuth } = useAuth();
  const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const bookId = props.bookId;
  const trigger = props.trigger;

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/books/${bookId}/reviews/`, {
        headers: {
          Authorization: `Bearer ${sessionAuth.token}`,
        },
      })
      .then((res) => setReviews(res.data))
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  }, [bookId, trigger]);

  return loading ? (
    <Spinner />
  ) : error ? (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>'Error: '</AlertTitle>
      <AlertDescription> {error} </AlertDescription>
    </Alert>
  ) : reviews?.length ? (
    <Grid margin="3%" templateColumns="repeat(1, 1fr)" gap={2}>
      <Accordion defaultIndex={[0]} allowMultiple >
        <AccordionItem >
          <AccordionHeader>
            <Box flex="1" textAlign="left">
              Reviews
            </Box>
            <AccordionIcon />
          </AccordionHeader>
          <AccordionPanel pb={4}   >
            {reviews.map((review, index) => <Review isTop={!index} key={review.id} review={review} bookId={bookId} userRecord={props.userRecord} triggerRefresh={props.triggerRefresh} />)}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Grid>
  ) : null;
};

export default Reviews;
