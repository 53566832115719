import React, { useState } from 'react';
import RatingStars from '../RatingStars/RatingStars';
import { API_URL } from '../../common/common.js';
import Reviews from '../../containers/Reviews';
import {
  Image,
  Tag,
  TagLabel,
  Tooltip,
  Text,
  Box,
  Heading,
  Flex,
  Stack,
} from '@chakra-ui/core';
import Bookmark from '../Bookmark/Bookmark';
import BookActions from '../BookActions/BookActions';
import { useAuth } from '../../context/auth';
import Loader from '../Loader/Loader';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import useUserBookData from '../../hooks/user-book-data';
import { NavLink } from 'react-router-dom';

const BookDetails = ({ book }) => {
  const { sessionAuth } = useAuth();
  const [trigger, setTrigger] = useState(false);
  const [bookmark, setBookMark] = useState(book.status);
  const [openReviews, setOpenReviews] = useState(false);

  const userRecord = useUserBookData(sessionAuth.user.id, book.id, !trigger);
  const triggerRefresh = () => setTrigger(!trigger);

  return (
    <Flex alignItems="center" margin="2%" marginLeft="5%">
      <Box p={4} display={{ sm: 'block', lg: 'flex' }}>
        <Box flexShrink="0">
          <div
            style={{
              marginRight: '5%',
              marginBottom: '10%',
              maxWidth: '500px',
            }}
          >
            <Tooltip hasArrow label={book.title} placement="bottom">
              <Image
                src={`${API_URL}/images/${book.cover}`}
                shadow="md"
                borderWidth="1px"
                width="500px"
                alt="BookDetails-cover"
                fallbackSrc={`${API_URL}/images/default_book.png`}
              ></Image>
            </Tooltip>
            <Bookmark status={bookmark} />
          </div>
        </Box>
        <Box
          mt={{ base: 4, md: 0 }}
          ml={{ md: 6 }}
          style={{ borderLeft: '1px solid #ddd' }}
          padding="2%"
          minWidth="450px"
        >
          <Heading fontSize="2xl">{book.title}</Heading>
          <Heading fontSize="md">
            <Tooltip hasArrow label={`Search books by ${book.author}`} placement="bottom" zIndex="1000">
              <NavLink to={{ pathname: '/books/', queryString: `&author=${book.author}` }}>
                {book.author}
              </NavLink>
            </Tooltip>
            <Tooltip hasArrow label={`Search books from year ${book.year}`} placement="bottom" zIndex="1000">
              <NavLink to={{ pathname: '/books/', queryString: `&year=${book.year}` }}>
                ({book.year})
            </NavLink>
            </Tooltip>
          </Heading>
          <Stack spacing={2} isInline marginBottom="10px" marginTop="10px">
            <Tag variantColor="gray">
              <Tooltip hasArrow label={`Search books in ${book.language}`} placement="bottom" zIndex="1000">
                <NavLink to={{ pathname: '/books/', queryString: `&languageId=${book.languageId}` }}>
                  <TagLabel fontSize="sm" className="book-language">
                    {book.language}
                  </TagLabel>
                </NavLink>
              </Tooltip>
            </Tag>
            <Tag variantColor="gray">
              <Tooltip hasArrow label={`Search ${book.genre} books`} placement="bottom" zIndex="1000">
                <NavLink to={{ pathname: '/books/', queryString: `&genreId=${book.genreId}` }}>
                  <TagLabel fontSize="sm" className="book-genre">
                    {book.genre}
                  </TagLabel>
                </NavLink>
              </Tooltip>
            </Tag>
            <Box>
              <Tooltip hasArrow label={`Search rating at least ${Math.floor(book.rating)}`} placement="top" zIndex="1000">
                <NavLink to={{ pathname: '/books/', queryString: `&rating=${Math.floor(book.rating)}` }}>
                  <RatingStars isHalf={true} edit={false} rating={book.rating}></RatingStars>
                </NavLink>
              </Tooltip>
            </Box>
          </Stack>
          <Text fontStyle={book.description ? 'normal' : 'italic'}>
            {book.description || '<No description.>'}
          </Text>
          {userRecord.isLoading ? (
            <Loader />
          ) : userRecord.error ? (
            <ErrorAlert status="error" error={userRecord.error} />
          ) : (
                <>
                  <Box marginTop="20px">
                    <BookActions
                      userRecord={userRecord}
                      triggerRefresh={triggerRefresh}
                      bookStatus={book.status}
                      setBookMark={setBookMark}
                      setOpenReviews={setOpenReviews}
                      title={book.title}
                      bookId={book.id}
                    />
                  </Box>
                  <Box marginTop="5px">
                    <Reviews
                      userRecord={userRecord}
                      bookId={book.id}
                      trigger={trigger}
                      openReviews={openReviews}
                      triggerRefresh={triggerRefresh}
                    />
                  </Box>
                </>
              )}
        </Box>
      </Box>
    </Flex>
  );
};

export default BookDetails;
