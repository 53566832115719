
import React from 'react';
import './ErrorLabel.css'

const ErrorLabel = ({message})=>{
return (
    <>
    <span className="ErrorLabel" >{message}</span>
    </>
  );
}
  export default ErrorLabel;