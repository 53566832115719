import React, { useEffect, useState } from 'react';
import {
    Stack, Input, Select, Grid,
    Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader,
    DrawerFooter, Button, useDisclosure, Icon, Tooltip, Checkbox, Box
} from '@chakra-ui/core';
import useEnums from '../../hooks/use-enums.js';
import SelectLevel from '../SelectLevel/SelectLevel';

const SearchUsers = (props) => {

    const [username, setUsername] = useState(null);
    const [levelId, setLevelId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(null);
    const [isBanned, setIsBanned] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [perPage, setPerPage] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const btnRef = React.useRef();
    const firstField = React.useRef();
    const enums = useEnums();

    const resetState = () => {
        setUsername(null);
        setLevelId(null);
        setIsAdmin(null);
        setIsBanned(null);
        setSortBy(null);
        setSortOrder(null);
        setPerPage(null);
    }

    const getQueryString = () => {
        let queryString = '';

        if (username) {
            queryString += '&username=' + username;
        }
        if (levelId) {
            queryString += '&levelId=' + levelId;
        }
        if (isAdmin) {
            queryString += '&isAdmin=' + isAdmin;
        }
        if (isBanned) {
            queryString += '&isBanned=' + isBanned;
        }
        if (sortBy) {
            queryString += '&sortBy=' + sortBy;
        }
        if (sortOrder) {
            queryString += '&sortOrder=' + sortOrder;
        }
        if (perPage) {
            queryString += '&perPage=' + perPage;
        }

        return queryString;
    }

    const checkForEnterKey = (e) => {
        if (e.which === 13) {
            handleSubmit();
        }
    }

    const handleInputChange = (e, setter) => {
        const inputValue = e.target.value;
        setter(inputValue);
    };

    const handleSubmit = () => {
        const toPath = '/admin/users';
        if (props.location.pathname === toPath) {
            props.history.replace({
                pathname: '/empty',
                queryString: getQueryString(),
            });
            setTimeout(() => {
                props.history.replace({
                    pathname: toPath,
                    queryString: getQueryString(),
                });
            });

        } else {
            props.history.push({
                pathname: toPath,
                queryString: getQueryString(),
            });
        }
        onClose();
    }

    useEffect(() => {
        resetState();
    }, [isOpen]);

    return (

        <>
            <Tooltip hasArrow label={'Click to Search users'} placement="bottom" zIndex="1000">
                <Icon color='black' aria-label="Search database" name="search-2" size="20px" onClick={onOpen} style={{ cursor: 'pointer' }} />
            </Tooltip>
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                finalFocusRef={btnRef}
                initialFocusRef={firstField}
                maxW="50%"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Admin Search users by...</DrawerHeader>
                    <Grid align="center" justify="center" margin="8%">
                        <Stack justifyItems="center">
                            <Input ref={firstField} color="black" placeholder="Username..."
                                onChange={(e) => handleInputChange(e, setUsername)}
                                onKeyPress={(e) => checkForEnterKey(e)}
                            />
                            <Stack isInline>
                                <Box align="start" aligntItems="start" justifyContent="start" float="left" width="50%">
                                    <Checkbox variantColor="orange" name="isAdmin" value="1" onChange={(e) => handleInputChange(e, setIsAdmin)}>
                                        Admin
                                    </Checkbox>
                                </Box>
                                <Box float="right" align="end" aligntItems="end" justifyContent="end">
                                    <Checkbox variantColor="red" name="isBanned" value="1" onChange={(e) => handleInputChange(e, setIsBanned)}>
                                        Banned
                                    </Checkbox>
                                </Box>
                            </Stack>
                            <SelectLevel enums={enums} onChange={(e) => handleInputChange(e, setLevelId)} />

                            <Select color="black" placeholder="Sort by..." variant="filled" onChange={(e) => handleInputChange(e, setSortBy)}>
                                <option value="username">Username</option>
                                <option value="levelId">Level</option>
                                <option value="points">Points</option>
                                <option value="isAdmin">Admin</option>
                                <option value="isBanned">Banned</option>
                                <option value="createDate">Registration date</option>
                            </Select>
                            <Select color="black" placeholder="Sort order..." variant="filled" onChange={(e) => handleInputChange(e, setSortOrder)}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </Select>
                            <Select color="black" placeholder="Per page..." variant="filled" onChange={(e) => handleInputChange(e, setPerPage)}>
                                <option value="6">6</option>
                                <option value="12">12</option>
                                <option value="24">24</option>
                                <option value="36">36</option>
                                <option value="48">48</option>
                                <option value="60">60</option>
                            </Select>
                        </Stack>
                    </Grid>
                    <DrawerFooter>
                        <Button
                            variant="outline"
                            marginRight="1%"
                            _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                            bg="brand.black1"
                            color="brand.white1"
                            onClick={() => handleSubmit()}
                        >
                            Search
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
}

export default SearchUsers;
