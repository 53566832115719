import React, { useState } from 'react';
import axios from 'axios';
import ErrorAlert from '../components/ErrorAlert/ErrorAlert';
import { API_URL } from '../common/common.js';
import { useAuth } from '../context/auth';
import {
  Button,
  Input,
  Icon,
  Modal,
  useDisclosure,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Text,
  useToast,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/core';
import validateParam, { enumData } from '../validator/validator';

const BanUserModal = ({
  userId,
  username,
  banStatus,
  updateBanTag
}) => {
  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [days, setDays] = useState(enumData.minDays);
  const [editReason, setEditReason] = useState(null);
  const [isBanned, setIsBanned] = useState(banStatus);

  const toast = useToast();
  const initialRef = React.useRef();

  const manageBanStatus = (days, reason) => {
    setLoading(true);
    setError(null);
    const method = isBanned ? 'put' : 'post';
    const banData = isBanned ? {} : {
      days: days,
      reason: reason,
    };
    axios[method](
      `${API_URL}/users/${userId}/ban`,
      banData,
      {
        headers: {
          Authorization: `Bearer ${sessionAuth.token}`,
        },
      }
    ).then(() => {
      toast({
        title: `You have ${isBanned ? 'unbanned' : 'banned'} user ${username}`,
        description: '',
        status: 'success',
        duration: enumData.toastDuration,
        isClosable: true,
      });
      const status = !isBanned;
      setIsBanned(status);
      updateBanTag(status);
      onClose();
    }).catch((error) => {
      if (error.response) {
        setError(error.response.data.error);
      }
    })
      .finally(() => setLoading(false));
  };

  const banUser = (days, editReason) => {
    
    alert('This feature has been disabled!');
  };
  return (<>

    <Icon name={isBanned ? 'unlock' : 'lock'} marginRight="5px" onClick={onOpen} />
    <Modal
      initialFocusRef={initialRef}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>   {isBanned ? 'Remove ban of' : 'Ban'} user {username}</ModalHeader>
        <ModalBody>
          <ModalCloseButton
            bg="brand.red1"
            color="brand.white1"
            _hover={{ bg: 'brand.black1', color: 'brand.white1' }}
          />
        </ModalBody>
        {!loading ? error && <ErrorAlert error={error} /> : null}
        <ModalFooter>
          {!loading && (
            isBanned ? <>
              <Text >The user will be able to post content </Text>
              <span>&nbsp;</span>
              <Button
                marginRight="1%"
                _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                bg="brand.black1"
                color="brand.white1"
                onClick={() => banUser(days, editReason)}
              >
                Remove ban
              </Button> </> : <><Text fontWeight="Bold">Days </Text>
                <NumberInput maxWidth="80px" marginLeft="5%" defaultValue={1} min={1} >
                  <NumberInputField onChange={(e) => {
                    setDays(e.target.value)
                  }} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Input
                  marginRight="1%"
                  onChange={(e) => setEditReason(e.target.value)}
                  placeholder={editReason}
                ></Input>
                <Button
                  marginRight="1%"
                  _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                  bg="brand.black1"
                  color="brand.white1"
                  onClick={() => banUser(days, editReason)}
                >
                  Ban
                </Button>
              </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>);
};

export default BanUserModal;

