import React from 'react';
import { Stack } from '@chakra-ui/core';
import PaginationButton from './PaginationButton';

const Pagination = ({ totalCount, page, perPage, setPage }) => {
  const pageCount = Math.ceil(totalCount / perPage);

  const handlePageClick = (clickedPage) => {
    if (clickedPage === page) return;
    setPage(clickedPage);
  };

  const getPageNumbers = (pageCount) => {
    let content = [];
    for (let i = 1; i <= pageCount; i++) {
      content.push(
        <PaginationButton label={i} value={i} handler={handlePageClick} selected={i === page} />
      );
    }
    return content;
  };

  return (
    <>
      <Stack isInline justify="center" alignItems="center">
        {page !== 1 && <PaginationButton label='<<' value='1' handler={handlePageClick} />}
        {page > 1 ? <PaginationButton label='Prev' value={page - 1} handler={handlePageClick} />
          : null}
        {pageCount > 1 && getPageNumbers(pageCount)}
        {pageCount > page ? <PaginationButton label='Next' value={page + 1} handler={handlePageClick} />
          : null}
        {pageCount && page !== pageCount && <PaginationButton label='>>' value={pageCount} handler={handlePageClick} />}
      </Stack>
    </>
  );
};

export default Pagination;