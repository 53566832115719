import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ErrorAlert from '../components/ErrorAlert/ErrorAlert';
import { API_URL } from '../common/common.js';
import { useAuth } from '../context/auth';
import {
  Button,
  Input,
  Modal,
  useDisclosure,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Textarea,
  useToast,
  ModalCloseButton,
} from '@chakra-ui/core';

import Loader from '../components/Loader/Loader';
import RatingStars from '../components/RatingStars/RatingStars';
import validateParam, { enumData } from '../validator/validator';
import ErrorLabel from '../components/ErrorLabel/ErrorLabel';

const ReviewModal = ({
  bookId,
  title,
  userRecord,
  triggerRefresh,
}) => {
  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorClient, setErrorClient] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [editReason, setEditReason] = useState(null);

  const toast = useToast();
  const initialRef = React.useRef();
  const userReview = userRecord.reviews?.find((rev) => rev.bookId === bookId);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (userReview) {
      setReview(userReview.content);
      setRating(userReview.rating);
      setEditReason(null);
    }
  }, [isOpen, userReview]);

  const rateBook = (rating) => {
    setLoading(true);
    setError(null);
    axios
      .post(
        `${API_URL}/books/${bookId}/ratings/`,
        { rating: +rating },
        {
          headers: {
            Authorization: `Bearer ${sessionAuth.token}`,
          },
        }
      )
      .then((res) => {
        toast({
          title: res.data.message,
          description: '',
          status: 'warning',
          duration: enumData.toastDuration,
          isClosable: true,
        });
        onClose();
      }).then(() => triggerRefresh())
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      }).finally(() => setLoading(false));
  };
  const reviewBook = (review, rating) => {
    setLoading(true);
    setError(null);
    axios
      .post(
        `${API_URL}/books/${bookId}/reviews/`,
        {
          content: review,
          editReason: '',
        },
        {
          headers: {
            Authorization: `Bearer ${sessionAuth.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.message) {
          onClose();
          setTimeout(() => {
            toast({
              title: res.data.message,
              description: '',
              status: 'success',
              duration: enumData.toastDuration,
              isClosable: true,
            });
          }, 500);
          toast({
            description: '',
            title: 'You gain +10 points',
            duration: enumData.toastDuration,
            isClosable: true,
          });
        }
      }).then(() => rateBook(rating))
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  };

  const editReview = (id, review, editReason, rating) => {
    setLoading(true);
    setError(null);
    axios
      .put(
        `${API_URL}/books/${bookId}/reviews/${id}`,
        {
          content: review,
          editReason: editReason,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionAuth.token}`,
          },
        }
      ).then(() => {
        toast({
          title: 'Review is updated.',
          description: '',
          status: 'success',
          duration: enumData.toastDuration,
          isClosable: true,
        });
      })
      .then(() => {
        if (rating !== userReview.rating) {
          rateBook(rating);
        } else {
          triggerRefresh();
          onClose();
        }
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  };

  const deleteReview = () => {
    setLoading(true);
    setError(null);
    axios
      .delete(
        `${API_URL}/books/${bookId}/reviews/${userReview.id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionAuth.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.message) {
          setTimeout(() => {
            toast({
              title: 'Review is deleted.',
              description: '',
              status: 'success',
              duration: enumData.toastDuration,
              isClosable: true,
            });
          }, 500);
          toast({
            description: '',
            title: 'You lose -10 points',
            status: 'error',
            duration: enumData.toastDuration,
            isClosable: true,
          });
          setReview('');
          setRating(0);
          setEditReason(null);
          triggerRefresh();
          onClose();
        }
      }).catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => {
        setLoading(false);

      });
  };

  const submitReviewRating = (review, rating) => {
    alert('This feature has been disabled!');
  };

  const updateReviewRating = (review, rating, editReason) => {
    if (userReview.content === review && rating === userReview.rating) {
      return onClose();
    }
    const validatedReview = validateParam('review', review);
    if (validatedReview) {
      return setErrorClient(validatedReview);
    }
    const validatedRating = validateParam('rating', rating);
    if (validatedRating) {
      return setErrorClient(validatedRating);
    }
    if (userReview.content === review && rating !== userReview.rating) {
      return rateBook(rating);
    }
    else {
      editReview(userReview.id, review, editReason, rating);
      setErrorClient(null)
    }
  };

  const ratingChanged = (stars) => {
    setRating(stars);
  };

  return (
    <>
      <Button
        _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
        bg="brand.black1"
        color="brand.white1"
        onClick={onOpen}
      >
        {userReview ? 'Edit Review' : 'Review'}
      </Button>
      <Modal
        initialFocusRef={initialRef}
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ fontWeight: 750 }}>
            {userReview ? 'Edit Rating and Review' : 'Rate and Review'}
          </ModalHeader>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <div>{userReview ? 'Edit rating' : 'Please, give a rating'}</div>
            <ModalCloseButton
              bg="brand.red1"
              color="brand.white1"
              _hover={{ bg: 'brand.black1', color: 'brand.white1' }}
            />
            {!loading ? (
              <div style={{ maxWidth: '110px' }}>
                <RatingStars
                  rating={rating}
                  isHalf={false}
                  edit={true}
                  style={{ float: 'left' }}
                  onChange={ratingChanged}
                />
              </div>
            ) : (
                <Loader />
              )}
            <div>
              {userReview
                ? 'Edit existing review'
                : 'Leave your thoughts on the book'}
            </div>
            {!loading ? errorClient && <ErrorLabel message={errorClient} /> : null}
            {!loading ? error && <ErrorAlert error={error} /> : null}
            <Textarea
              ref={initialRef}
              marginTop="2%"
              value={review}
              onChange={(e) => setReview(e.target.value)}
            ></Textarea>
          </ModalBody>
          <ModalFooter>
            {!loading && !userReview ? (
              <Button
                marginRight="80%"
                _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                bg="brand.black1"
                color="brand.white1"
                onClick={() => submitReviewRating(review, rating)}
              >
                Submit
              </Button>
            ) : (
                <>
                  <Button
                    marginRight="1%"
                    _hover={{ bg: 'brand.red1', color: 'brand.white' }}
                    bg="brand.black1"
                    color="brand.white1"
                    onClick={() => deleteReview()}
                  >
                    Delete
                </Button>
                  <Input marginRight="1%"
                    onChange={(e) => setEditReason(e.target.value)}
                    placeholder='Edit reason...'
                  ></Input>
                  <Button
                    marginRight="1%"
                    _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                    bg="brand.black1"
                    color="brand.white1"
                    onClick={() => updateReviewRating(review, rating, editReason)}
                  >
                    Update
                </Button>
                </>
              )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReviewModal;
