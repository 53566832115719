import { useState, useEffect } from 'react';
import { API_URL } from '../common/common.js';
import axios from 'axios';
import { useAuth } from '../context/auth';

const useUserBookData = (userId, bookId, trigger) => {
  const { sessionAuth } = useAuth();
  const [userBookData, setUserBookData] = useState({
    borrow: null,
    reviews: null,
    comments: null,
    likes: null,
    isRead: false,
    isLoading: true,
    error: null,
  });

  useEffect(() => {

    const authHeader = {
      headers: {
        Authorization: `Bearer ${sessionAuth.token}`,
      },
    };
    axios
      .all([
        axios.get(`${API_URL}/books/${bookId}/borrowing/active`, authHeader),
        axios.get(`${API_URL}/users/${userId}/reviews`, authHeader),
        axios.get(`${API_URL}/users/${userId}/comments`, authHeader),
        axios.get(`${API_URL}/users/${userId}/likes`, authHeader),
        axios.get(`${API_URL}/books/${bookId}/read`, authHeader),
      ])
      .then(
        axios.spread((borrows, reviews, comments, likes, isRead) => {
          setUserBookData({
            borrows: borrows.data,
            reviews: reviews.data,
            comments: comments.data,
            likes: likes.data,
            isRead: isRead.data,
            isLoading: false,
            error: null,
          });
        })
      )
      .catch((error) => {
        if (error.response) {
          setUserBookData({
            borrow: null,
            reviews: null,
            comments: null,
            likes: null,
            isRead: false,
            isLoading: false,
            error: error.response.data.error,
          });
        }
      });
  }, [trigger]);

  return userBookData;
};

export default useUserBookData;
