import React, { useState, useEffect } from 'react';
import { FormLabel, Image, Tooltip, Box } from '@chakra-ui/core';
import { API_URL } from '../../common/common.js';
import axios from 'axios';
import { useAuth } from '../../context/auth';

const UploadImage = ({ type, trigger, setter, defaultImage, setterNew }) => {
  const { sessionAuth } = useAuth();
  const [upload, setUpload] = useState(null);
  const [fileUrl, setFileUrl] = useState(() => null);

  const sendUpload = (upload) => {
    return;
  };

  function processImage(event) {
    const imageFile = event.target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setFileUrl(imageUrl);
    setUpload(imageFile);
    setterNew && setterNew(imageFile);
  }

  useEffect(() => {
    sendUpload(upload);
  }, [trigger]);
  return (
    <Box>
      <Tooltip hasArrow label={`Upload ${type}`} placement="right">
        {
          {
            avatar: <Image src={fileUrl || defaultImage} fallbackSrc={`${API_URL}/images/default_avatar.jpg`}
              shadow="md"
              borderWidth="1px"
              alt="Avatar-image" />,
            cover: (
              <Image
                src={fileUrl || defaultImage}
                fallbackSrc={`${API_URL}/images/default_book.png`}
                shadow="md"
                borderWidth="1px"
                alt="BookDetails-cover"
              ></Image>
            ),
          }[type]
        }
      </Tooltip>
      <FormLabel>Select image:</FormLabel>
      <input type="file" accept="image/*" onChange={processImage}></input>
      <input
        style={{ padding: '10px', display: 'none' }}
        type="submit"
        name="btn_upload_profile_pic"
        value="Upload"
      />
    </Box>
  );
};

export default UploadImage;
