import React from 'react';
import './UserAvatar.css';
import { Avatar, Text, Box, Grid, Tooltip } from '@chakra-ui/core';
import { API_URL } from '../../common/common';
import { useAuth } from '../../context/auth';

const UserAvatar = ({ userAvatar, userName, userLevel, avatarSize }) => {
  const { sessionAuth } = useAuth();

  return (
    <Tooltip hasArrow label={sessionAuth ? 'View profile' : 'Login to view profile'} placement="bottom" zIndex="1000">
      <Grid className="avatar-grid" style={{ maxWidth: '200px', width: '100px' }} gap={2}>
        <Box className="box1">
          <Avatar size={avatarSize || "md"} src={`${API_URL}/images/${userAvatar}`} />
        </Box>
        <Box>
          {userName && <Text marginTop="-5px" fontSize="md">{userName}</Text>}
          {userLevel && <Text fontSize="xs">{userLevel}</Text>}
        </Box>
      </Grid>
    </Tooltip>
  );
};

export default UserAvatar;
