import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { API_URL } from '../../common/common.js';
import UploadImage from '../UploadImage/UploadImage';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import axios from 'axios';
import useEnums from '../../hooks/use-enums.js';
import validateParam, { enumData } from '../../validator/validator';
import { Switch, Box, Grid, Button, Input, useToast, FormLabel } from '@chakra-ui/core';
import { useAuth } from '../../context/auth';
import ErrorLabel from '../ErrorLabel/ErrorLabel';

const schema = { username: false, email: false, password: false };

const EditUser = withRouter(({ user, history }) => {
  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(() => schema);
  const [trigger, setTrigger] = useState(false);
  const [username, setUsername] = useState(user.username);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState(user.password);
  const [isAdmin, setIsAdmin] = useState(!!user.isAdmin);
  const [avatar, setAvatar] = useState(user.avatar);
  const [newAvatar, setNewAvatar] = useState(user.avatar);

  const toast = useToast();

  const refreshPage = () => {
    history.replace({
      pathname: '/empty',
    });
    setTimeout(() => {
      history.replace(
        {
          pathname: '/admin/users',
        },
        0
      );
    });
  };
  const enums = useEnums();
  
  const toggleAdmin = () => {
    return;
  }
  const update = (data) => {
    return;
  }

  const handleSubmit = () => {
    
    alert('This feature has been disabled!');

  }

  useEffect(() => {
    if (!trigger) {
      return;
    }
    update({
      username,
      email,
      password,
      avatar
    });
  }, [avatar]);

  const handleInputChange = (e, setter) => {
    const inputValue = e.target.value;
    const name = e.target.name;
    const error = validateParam(name, inputValue);
    const errorObj = { ...validationError };
    setter(inputValue);
    if (error) {
      errorObj[name] = error;
      return setValidationError(errorObj);
    } else {
      errorObj[name] = null;
      return setValidationError(errorObj);
    }
  };

  return (
    <>
      {!loading || !enums.isLoading
        ? (error || enums.error) && <ErrorAlert error={error || enums.error} />
        : null}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Grid margin="3%" templateColumns="70% 30%" width="600px" gap={6}>
          <Box p={5} shadow="md" borderWidth="1px">
            <Input
              name="username"
              placeholder="username...<required>"
              value={username}
              onChange={(e) => handleInputChange(e, setUsername)}
            />
            {validationError['username'] ? (
              <ErrorLabel message={validationError['username']}></ErrorLabel>
            ) : (
                null
              )}
            <Input
              name="password"
              placeholder="new password..."
              onChange={(e) => handleInputChange(e, setPassword)}
            />
            {validationError['password'] ? (
              <ErrorLabel message={validationError['password']}></ErrorLabel>
            ) : (
                null
              )}
            <Input
              name="email"
              placeholder="email..."
              value={email}
              onChange={(e) => handleInputChange(e, setEmail)}
            />
            {validationError['email'] ? (
              <ErrorLabel message={validationError['email']}></ErrorLabel>
            ) : (
                null
              )}
            <FormLabel htmlFor="adminSwitch">Grant Admin</FormLabel>
            <Switch id="adminSwitch" size="lg" color="orange" isChecked={isAdmin} onChange={(e) => {
              e.target.isChecked = !isAdmin;
              setIsAdmin(!!e.target.isChecked)
            }} />
          </Box>
          <Box p={5}>
            <UploadImage
              defaultImage={`${API_URL}/images/` + avatar}
              type={'avatar'}
              trigger={trigger}
              setter={setAvatar}
              setterNew={setNewAvatar}
            ></UploadImage>
          </Box>
          <Button
            _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
            bg="brand.black1"
            color="brand.white1"
            maxWidth="100px"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
              </Button>
        </Grid>
      </Box>
    </>
  );
});

export default EditUser;




















