import React from 'react';
import Comments from '../../containers/Comments';
import UserAvatar from '../UserAvatar/UserAvatar';
import { Text, Box, Stack } from '@chakra-ui/core';
import DateView from '../DateView/DateView';
import './Review.css';
import UserModal from '../UserModal/UserModal';
import RatingStars from '../RatingStars/RatingStars';
import LikeReview from '../LikeReview/LikeReview';
import CreateCommentModal from '../../containers/CreateCommentModal';

const Review = ({ isTop, review, bookId, userRecord, triggerRefresh }) => {

  return (
    <Box id={`r${review.id}`}>
      {isTop ? <Text fontWeight='Bold' p="10px">Top Review</Text> : null}
      <LikeReview likesCount={review.likes} bookId={bookId} reviewId={review.id} userLikes={userRecord.likes} />
      <UserModal userId={review.userId}>
        <UserAvatar
          userAvatar={review.userAvatar}
          userName={review.userName}
          userLevel={review.userLevel}
        />
      </UserModal>
      <RatingStars rating={review.rating} isHalf={false} edit={false}></RatingStars>
      <Text fontSize="xl">{review.content}</Text>
      <Stack isInline>
        {review.updateDate ? (
          <Text fontSize="xs" style={{ float: 'left' }}>
            Edited on:&nbsp;
            <DateView
              style={{ float: 'right' }}
              size={'xs'}
              date={review.updateDate}
            />
          </Text>
        ) : (
            <DateView size={'xs'} date={review.createDate} />
          )}
        {review.editReason ? (
          <Text fontSize="xs">Reason: {review.editReason}</Text>
        ) : null}
      </Stack>
      <CreateCommentModal bookId={bookId} review={review} triggerRefresh={triggerRefresh} />
      <Comments bookId={review.bookId} review={review} userRecord={userRecord} triggerRefresh={triggerRefresh} />
    </Box>
  );
};

export default Review;
