import React, { useState } from 'react';
import { API_URL } from '../../common/common.js';
import {
  Image,
  Tooltip,
  Stack,
  Text,
  Box,
  Tag,
  TagLabel,
  Icon,
  PseudoBox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/core';
import { NavLink, withRouter } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import axios from 'axios';
import ErrorAlert from '../ErrorAlert/ErrorAlert';

const BookListItem = withRouter(({ book, history }) => {
  const { sessionAuth } = useAuth();
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const toast = useToast();
  const openDeletePopup = () => setIsOpen(!isOpen);
  const closeDeletePopup = () => setIsOpen(false);
  const initialFocusRef = React.useRef();

  const refreshPage = () => {
    history.replace({
      pathname: '/empty',
    });
    setTimeout(() => {
      history.replace(
        {
          pathname: '/admin/books',
        },
        0
      );
    });
  };

  const deleteBook = () => {
    closeDeletePopup();
    
    alert('This feature has been disabled!');
  };

  return (
    <PseudoBox _hover={{ bg: 'gray.200' }} width="100%">
      <Stack isInline justifyContent="flex-end">
        <Box className="BookItem-body">
          <Tooltip hasArrow label={book.title} placement="bottom" zIndex="1000">
            <Image
              width="30px"
              height="45px"
              className="BookItem-cover"
              src={`${API_URL}/images/${book.cover}`}
              fallbackSrc={`${API_URL}/images/default_book.png`}
              alt="BookItem-cover"
            ></Image>
          </Tooltip>
        </Box>
        <Box className="BookItem-Info" isTruncated width="180px">
          <Text isTruncated className="title-name">
            {book.title}
          </Text>
          <Text isTruncated className="author-name">
            <Tooltip
              hasArrow
              label={`Search books by ${book.author}`}
              placement="bottom"
              zIndex="1000"
            >
              <NavLink
                to={{
                  pathname: '/admin/books',
                  queryString: `&author=${book.author}`,
                  state: { admin: true },
                }}
              >
                {book.author}
              </NavLink>
            </Tooltip>
            <Tooltip
              hasArrow
              label={`Search books from year ${book.year}`}
              placement="bottom"
              zIndex="1000"
            >
              <NavLink
                to={{
                  pathname: '/admin/books',
                  queryString: `&year=${book.year}`,
                  state: { admin: true },
                }}
              >
                ({book.year})
              </NavLink>
            </Tooltip>
          </Text>
        </Box>
        <Box marginTop="8px" width="420px">
          <Tag variantColor="gray" marginRight="5px">
            <Tooltip
              hasArrow
              label={`Search books in ${book.language}`}
              placement="bottom"
              zIndex="1000"
            >
              <NavLink
                to={{
                  pathname: '/admin/books',
                  queryString: `&languageId=${book.languageId}`,
                  state: { admin: true },
                }}
              >
                <TagLabel fontSize="xs" className="book-language">
                  {book.language}
                </TagLabel>
              </NavLink>
            </Tooltip>
          </Tag>
          <Tag variantColor="gray" marginRight="5px">
            <Tooltip
              hasArrow
              label={`Search ${book.genre} books`}
              placement="bottom"
              zIndex="1000"
            >
              <NavLink
                to={{
                  pathname: '/admin/books',
                  queryString: `&genreId=${book.genreId}`,
                  state: { admin: true },
                }}
              >
                <TagLabel fontSize="xs" className="book-genre">
                  {book.genre}
                </TagLabel>
              </NavLink>
            </Tooltip>
          </Tag>
          <Tag variantColor={book.statusId === 1 ? 'green' : 'red'}>
            <Tooltip
              hasArrow
              label={`Search ${book.status} books`}
              placement="bottom"
              zIndex="1000"
            >
              <NavLink
                to={{
                  pathname: '/admin/books',
                  queryString: `&statusId=${book.statusId}`,
                  state: { admin: true },
                }}
              >
                <TagLabel fontSize="xs" className="book-genre">
                  {book.status}
                </TagLabel>
              </NavLink>
            </Tooltip>
          </Tag>
          <Tag variantColor="transparent">
            <Tooltip
              hasArrow
              label={`Search by rating at least ${Math.floor(book.rating)}`}
              placement="bottom"
              zIndex="1000"
            >
              <NavLink
                to={{
                  pathname: '/admin/books',
                  queryString: `&rating=${Math.floor(book.rating)}`,
                  state: { admin: true },
                }}
              >
                <Icon name="star" marginRight="2px" />
                <TagLabel fontSize="sm" className="book-genre">
                  {book.rating}
                </TagLabel>
              </NavLink>
            </Tooltip>
          </Tag>
          <Tooltip hasArrow label={`Edit`} placement="bottom" zIndex="1000">
            <NavLink
              to={{
                pathname: `/admin/books/${book.id}`,
                state: { book: book },
              }}
            >
              <Icon name="edit" marginRight="5px" />
            </NavLink>
          </Tooltip>
          <Popover
            // returnFocusOnClose={false}
            initialFocusRef={initialFocusRef}
            isOpen={isOpen}
            onClose={closeDeletePopup}
            placement="right"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Tooltip
                hasArrow
                label="Delete"
                placement="bottom"
                zIndex="1000"
              >
                <NavLink to="#">
                  <Icon
                    name="delete"
                    onClick={openDeletePopup}
                  />
                </NavLink>
              </Tooltip>
            </PopoverTrigger>
            {error ? <ErrorAlert error={error} /> : null}
            <PopoverContent
              zIndex={4}
              width="240px"
              height="30px"
              marginLeft="100%"
              marginTop="-10%"
              bg="transparent"
              shadow="none"
              border="none"
            >
              <NavLink to="#">
                <PopoverBody fontSize="11px" color="brand.red1" fontWeight="Bold">
                  Delete this book?
                  <ButtonGroup size="xs" marginLeft="5%" verticalAlign>
                    <Button
                      variant="outline"
                      onClick={closeDeletePopup}
                      ref={initialFocusRef}
                      fontSize="10px"
                      color="black"
                    >
                      Cancel
                    </Button>
                    <Button
                      variantColor="red"
                      onClick={deleteBook}
                      fontSize="10px"
                    >
                      Yes
                    </Button>
                  </ButtonGroup>
                </PopoverBody>
              </NavLink>
            </PopoverContent>
          </Popover>
        </Box>
      </Stack>
    </PseudoBox>
  );
});
export default BookListItem;
