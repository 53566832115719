import React from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Heading, Flex, Image, Button, Grid } from '@chakra-ui/core';
import bookmark from '../../assets/icons/bookmark.svg';
import './Header.css';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import UserAvatar from '../UserAvatar/UserAvatar';
import UserModal from '../UserModal/UserModal';
import SearchBooks from '../SearchBooks/SearchBooks';

const Header = withRouter((props) => {
  const { sessionAuth, setSessionAuth } = useAuth();

  const logOut = () => {
    setSessionAuth(null);
  };

  return (
    <Flex
      as="nav"
      className="NavBar"
      {...props}
      height={{ sm: '100px', md: '70px' }}
    >
      <Image
        src={bookmark}
        marginTop="-5px"
        width="20px"
        alt="bookmark"
      ></Image>
      <NavLink to={`/`}>
        <Heading marginLeft="15px" as="h2" size="md" marginTop="-5px">
          TA Library
        </Heading>
      </NavLink>
      <span >&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <SearchBooks {...props} />
      <span>&nbsp;&nbsp;</span>
      <Box
        display={{ sm: 'flex', md: 'flex' }}
        mt={{ base: 1, md: 0 }}
      >
        <NavLink to={`/`}>
          <Button
            bg="brand.black1"
            size="sm"
            marginTop="-10px"
            color="brand.white1"
            _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
          >
            Home
          </Button>
        </NavLink>
        <span>&nbsp;</span>
        <NavLink to={`/books`}>
          <Button
            bg="brand.black1"
            size="sm"
            marginTop="-10px"
            color="brand.white1"
            _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
          >
            Books
          </Button>

        </NavLink>
        {!!sessionAuth?.user.isAdmin && (
          <>
            <span>&nbsp;</span>
            <NavLink to={`/admin/books`}>
              <Button
                _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                size="sm"
                marginTop="-10px"
                bg="brand.black1"
                color="brand.white1"
              >
                Admin
              </Button>
            </NavLink>
          </>
        )}
      </Box>
      <Box
        display={{ sm: 'block', md: 'flex' }}
        width={{ sm: 'full', md: 'auto' }}
        alignItems="center"
        flexGrow={1}
      ></Box>
      <Box display={{ sm: 'flex', md: 'flex' }} mt={{ base: 1, md: 0 }}>
        {!sessionAuth ? (
          <>
            <NavLink to={`/login`}>
              <Button
                bg="brand.black1"
                marginTop="-10px"
                size="sm"
                color="brand.white1"
                _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
              >
                Login
              </Button>
            </NavLink>
            <span>&nbsp;</span>
            <NavLink to={`/signup`}>
              <Button
                bg="brand.black1"
                marginTop="-10px"
                size="sm"
                color="brand.white1"
                _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
              >
                Sign up
              </Button>
            </NavLink>
          </>
        ) : (
            <Grid templateColumns="60% 40%" gap={2} marginTop="-5px">
              <Box display={{ sm: 'none', md: 'flex' }}>
                <UserModal userId={sessionAuth.user.id}>
                  <UserAvatar
                    userAvatar={sessionAuth.user.avatar}
                    userName={sessionAuth.user.username}
                    userLevel={sessionAuth.user.level}
                    avatarSize="sm"
                  />
                </UserModal>
              </Box>
              <Box display={{ sm: 'flex', md: 'none' }}>
                <UserModal userId={sessionAuth.user.id}>
                  <UserAvatar
                    userAvatar={sessionAuth.user.avatar}
                    userName={null}
                    userLevel={null}
                    avatarSize="sm"
                  />
                </UserModal>
              </Box>
              <Box>
                <Button
                  marginLeft={{ xs: '-50px', md: '0px' }}
                  bg="brand.black1"
                  color="brand.white1"
                  size="sm"
                  _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                  onClick={logOut}
                >
                  Logout
              </Button>
              </Box>
            </Grid>
          )}
      </Box>
    </Flex>
  );
});

export default Header;
