import React from 'react';
import {
  Tabs, TabList, Tab, TabPanel, TabPanels, Icon, Box, Tooltip, Stack, Grid
} from '@chakra-ui/core';
import Books from './Books'
import { NavLink } from 'react-router-dom';
import CreateBook from '../components/CreateBook/CreateBook';
import EditBook from '../components/EditBook/EditBook';
import CreateUser from '../components/CreateUser/CreateUser';
import SearchBooks from '../components/SearchBooks/SearchBooks';
import SearchUsers from '../components/SearchUsers/SearchUsers';
import Users from './Users';
import EditUser from '../components/EditUser/EditUser';

const Admin = (props) => {
  const currentLocation = props.location.pathname;
  const book = props.location.state?.book;
  const user = props.location.state?.user;
  const isAtBooks = currentLocation.includes('books');

  const handleRefresh = (tab) => {
    const toPath = `/admin/${tab}`;
    if (props.location.pathname === toPath) {
      props.history.replace({ pathname: '/empty' });
      setTimeout(() => {
        props.history.replace({ pathname: toPath });
      });
    } else {
      props.history.push({ pathname: toPath });
    }
  }

  return (
    <Grid alignItems="center" justifyItems="center" justofyContent="space-between">
      <Tabs variant="enclosed" index={isAtBooks ? 0 : 1}>
        <Stack isInline p={2} justifyContent="space-between">
          <TabList>
            <Tab _selected={{ color: 'white', bg: 'brand.green1' }} onClick={() => handleRefresh('books')}>
              Books
              </Tab>
            <Tab _selected={{ color: 'white', bg: 'brand.green1' }} onClick={() => handleRefresh('users')}>
              Users
              </Tab>
          </TabList>
          <Box>
            <Tooltip hasArrow label={`Add ${isAtBooks ? 'Book' : 'User'}`} placement="bottom" zIndex="1000">
              <NavLink to={{ pathname: `/admin/${isAtBooks ? 'books' : 'users'}/create` }}>
                <Icon marginRight="10px" fontWeight="bold" name="add" />
              </NavLink>
            </Tooltip>
            {isAtBooks ? <SearchBooks {...props} admin />
              : <SearchUsers {...props} />}

          </Box>
        </Stack>
        <TabPanels>
          <TabPanel>
            {currentLocation === '/admin/books' && <Books {...props} admin />}
            {currentLocation === '/admin/books/create' && <CreateBook {...props} />}
            {book && currentLocation === `/admin/books/${book.id}`
              && <EditBook book={props.location.state.book} />}
          </TabPanel>
          <TabPanel>
            {currentLocation === '/admin/users' && <Users {...props} />}
            {currentLocation === '/admin/users/create' && <CreateUser {...props} />}
            {user && currentLocation === `/admin/users/${user.id}`
              && <EditUser user={props.location.state.user} />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Grid>
  );
};

export default Admin;