import React from 'react';
import { Image, Flex } from '@chakra-ui/core';
import Heart from '../../assets/icons/like.svg';
import './Like.css';

const Like = ({ likes }) => {
  return (
    <Flex className="like-container" style={{ cursor: 'pointer' }} >
      <Image className="like-icon" src={Heart} />
      <span>&nbsp;</span>
      <span className="like-count" >{`${likes}`}</span>
    </Flex>
  );
};

export default Like;
