import React, { useState, useEffect } from 'react';
import { API_URL } from '../common/common.js';
import BookItem from '../components/BookItem/BookItem';
import BookListItem from '../components/BookListItem/BookListItem';
import { enumData } from '../validator/validator.js';
import {
  Grid,
  Box,
  Stack,
  Text,
} from '@chakra-ui/core';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/auth';
import Pagination from '../components/Pagination/Pagination';
import Loader from '../components/Loader/Loader';
import ErrorAlert from '../components/ErrorAlert/ErrorAlert';

const Books = (props) => {
  const { sessionAuth } = useAuth();
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(enumData.defaultPage);
  const [perPage, setPerPage] = useState(enumData.defaultPerPage);
  const [queryString, setQueryString] = useState(props.location.queryString);

  if (props.location.queryString && props.location.queryString !== queryString) {
    setQueryString(props.location.queryString);
    setPage(enumData.defaultPage);
  }

  const fromAdmin = props.admin || props.location.state?.admin;

  let query = `?page=${page}`;
  queryString
    ? query += queryString
    : query += `&perPage=${perPage}`;

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${API_URL}/books/search${query}`, {
        headers: {
          Authorization: `Bearer ${sessionAuth.token}`,
        },
      })
      .then((res) => {
        setBooks(res.data.data)
        setTotalCount(res.data.totalCount);
        setPage(res.data.page);
        setPerPage(res.data.perPage);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [page, queryString]);

  return loading ? (
    <Loader />
  ) : error ? (
    <ErrorAlert status="error" error={error} />
  ) : (
        <>
          {fromAdmin
            ?
            <Grid
              margin='1%'
              gap={2}
            >
              {books &&
                books.map((book, i) => (
                  <NavLink to={`/books/${book.id}`}>
                    <BookListItem book={book} key={book.id} />
                  </NavLink>
                ))}
            </Grid>
            :
            <Grid
              marginTop='1%'
              gap={2}
              alignItems="top"
              justifyItems="center"
              templateColumns={{
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(4, 1fr)',
                xl: 'repeat(6, 1fr)',
              }}
            >
              {books &&
                books.map((book) => (
                  <NavLink to={`/books/${book.id}`}>
                    <BookItem book={book} key={book.id} />
                  </NavLink>
                ))}
            </Grid>
          }
          <Stack isInline justify="center">
            <Text fontWeight="normal">Books found:</Text>
            <Text fontWeight="bold">{totalCount}</Text>
          </Stack>
          <Stack isInline justify="center">
            <Box>
              <Pagination totalCount={totalCount} page={page} perPage={perPage} setPage={(page) => setPage(page)} />
            </Box>
          </Stack>
          <br />
        </>
      );
};

export default Books;
