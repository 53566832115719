import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Books from './containers/Books';
import Book from './components/Book/Book';
import UserProfile from './components/UserProfile/UserProfile';
import Home from './containers/Home';
import Admin from './containers/Admin';
import Credentials from './containers/Credentials';
import Header from './components/Header/Header';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import { AuthContext } from './context/auth';
import PrivateRoute from './PrivateRoute';
import { customTheme } from './context/theme-settings.js';
import decode from "jwt-decode";

const App = () => {
  const existingSessionAuth = JSON.parse(localStorage.getItem("sessionTL"));
  const [sessionAuth, setSessionAuth] = useState(existingSessionAuth);

  const setSession = (data) => {
    if (data) {
      localStorage.setItem("sessionTL", JSON.stringify(data));
    } else {
      localStorage.removeItem("sessionTL");
    }
    setSessionAuth(data);
  }

  const manageTokenExpiration = () => {
    if (sessionAuth) {
      const token = sessionAuth.token;
      const currentTime = new Date().valueOf();
      const decoded = decode(token);
      const tokenExp = new Date(0).setUTCSeconds(decoded.exp).valueOf();

      if (tokenExp < currentTime) {
        setSession(null);
      } else {
        setTimeout(() => setSession(null), (tokenExp - currentTime));
      }
    }

    return true;
  }

  manageTokenExpiration();

  return (
    <AuthContext.Provider value={{ sessionAuth, setSessionAuth: setSession }}>
      <ThemeProvider theme={customTheme}>
        <CSSReset />
        <div className="App">
          <BrowserRouter>
            <Header />
            <Switch>
              {/* Public routes */}
              <Route path="/" exact component={Home} />
              <Route path="/login" component={Credentials} />
              <Route path="/signup" component={Credentials} />

              {/* Private routes */}
              <PrivateRoute path="/books" exact component={Books} />
              <PrivateRoute path="/books/:id" exact component={Book} />
              <PrivateRoute path="/admin" exact component={Admin} admin />
              <PrivateRoute path="/admin/books" exact component={Admin} admin />
              <PrivateRoute path="/admin/books/:id" exact component={Admin} admin />
              <PrivateRoute path="/admin/books/create" exact component={Admin} admin />
              <PrivateRoute path="/admin/users" exact component={Admin} admin />
              <PrivateRoute path="/admin/users/:id" exact component={Admin} admin />
              <PrivateRoute path="/admin/users/create" exact component={Admin} admin />
              <PrivateRoute path="/profile" component={UserProfile} />
            </Switch>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
