import React from 'react';
import { PseudoBox } from '@chakra-ui/core'

const PaginationButton = ({ label, value, selected, handler }) => {
    return (
        <PseudoBox
            as="button"
            rounded="md"
            bg={selected ? `gray.500` : `gray.200`}
            py={1}
            px={3}
            ml={2}
            fontWeight="bold"
            _hover={{ bg: "gray.500" }}
            _focus={{ boxShadow: "outline" }}
            onClick={() => handler(value)}>
            {label}
        </PseudoBox>
    )
}
export default PaginationButton;