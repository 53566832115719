import React, { useEffect, useState } from 'react';
import { API_URL } from '../../common/common.js';
import axios from 'axios';
import BookDetails from '../BookDetails/BookDetails';
import { useAuth } from '../../context/auth';
import Loader from '../Loader/Loader';
import ErrorAlert from '../ErrorAlert/ErrorAlert';

const Book = (props) => {
  const { sessionAuth } = useAuth();
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id } = props.match.params;

  useEffect(() => {
    setLoading(true);
    const authHeader = {
      headers: {
        Authorization: `Bearer ${sessionAuth.token}`,
      },
    };
    axios
      .get(`${API_URL}/books/${id}`, authHeader)
      .then((res) => {
        setBook(res.data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return loading ? (
    <Loader />
  ) : error ? (
    <ErrorAlert status="error" error={error} />
  ) : (
        book && (
          <BookDetails
            book={book}
          />
        )
      );
};

export default Book;
