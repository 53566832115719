import React, { useEffect, useState } from 'react';
import {
  Stack, Input, Select, Grid,
  Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader,
  DrawerFooter, Button, useDisclosure, Icon, Tooltip
} from '@chakra-ui/core';
import useEnums from '../../hooks/use-enums.js';
import SelectGenre from '../SelectGenre/SelectGenre';
import SelectLanguage from '../SelectLanguage/SelectLanguage';

const SearchBooks = (props) => {

  const [filterBy, setFilterBy] = useState(null);
  const [filter, setFilter] = useState(null);
  const [languageId, setLanguageId] = useState(null);
  const [genreId, setGenreId] = useState(null);
  const [rating, setRating] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [perPage, setPerPage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = React.useRef();
  const firstField = React.useRef();
  const enums = useEnums();

  const resetState = () => {
    setFilterBy(null);
    setFilter(null);
    setLanguageId(null);
    setGenreId(null);
    setRating(null);
    setStatusId(null);
    setSortBy(null);
    setSortOrder(null);
    setPerPage(null);
  }

  const getQueryString = () => {
    let queryString = '';

    if (filter) {
      queryString += '&' + (filterBy || 'title') + '=' + filter;
    }
    if (languageId) {
      queryString += '&languageId=' + languageId;
    }
    if (genreId) {
      queryString += '&genreId=' + genreId;
    }
    if (rating) {
      queryString += '&rating=' + rating;
    }
    if (statusId) {
      queryString += '&statusId=' + statusId;
    }
    if (sortBy) {
      queryString += '&sortBy=' + sortBy;
    }
    if (sortOrder) {
      queryString += '&sortOrder=' + sortOrder;
    }
    if (perPage) {
      queryString += '&perPage=' + perPage;
    }

    return queryString;
  }

  const checkForEnterKey = (e) => {
    if (e.which === 13) {
      handleSubmit();
    }
  }

  const handleInputChange = (e, setter) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
    const inputValue = e.target.value;
    setter(inputValue);
  };

  const toPath = props.admin ? '/admin/books' : '/books';

  const handleSubmit = () => {
    if (props.location.pathname === toPath) {
      props.history.replace({
        pathname: '/empty',
        queryString: getQueryString(),
      });
      setTimeout(() => {
        props.history.replace({
          pathname: toPath,
          queryString: getQueryString(),
        });
      });

    } else {
      props.history.push({
        pathname: toPath,
        queryString: getQueryString(),
      });
    }
    onClose();
  }

  useEffect(() => {
    resetState();
  }, [isOpen]);

  return (

    <>
      <Tooltip hasArrow label={`Click to ${props.admin ? 'Admin' : ''} Search books`} placement="bottom" zIndex="1000">
        <Icon color={props.admin ? 'black' : 'white'} aria-label="Search database" name="search-2" size="20px" onClick={onOpen} style={{ cursor: 'pointer' }} />
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        initialFocusRef={firstField}
        maxW="50%"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{props.admin ? 'Admin ' : null}Search books by...</DrawerHeader>
          <Grid align="center" justify="center" margin="8%">
            <Stack align="center" isInline justify="center" >
              <Select color="black" width="50%" placeholder="Filter..." variant="flushed" onChange={(e) => handleInputChange(e, setFilterBy)}>
                <option value="title">Title</option>
                <option value="author">Author</option>
                <option value="year">Year</option>
              </Select>
              <Input ref={firstField} value={filter} color="black" placeholder="Search term..."
                onChange={(e) => handleInputChange(e, setFilter)}
                onKeyPress={(e) => checkForEnterKey(e)}
              />
            </Stack>
            <Stack align="center" justify="center">
              <SelectLanguage enums={enums} onChange={(e) => handleInputChange(e, setLanguageId)} />
              <SelectGenre enums={enums} onChange={(e) => handleInputChange(e, setGenreId)} />
              <Select color="black" placeholder="Rating at least..." variant="flushed" onChange={(e) => handleInputChange(e, setRating)}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </Select>
              <Select color="black" placeholder="Book status..." variant="flushed" onChange={(e) => handleInputChange(e, setStatusId)}>
                <option value="1">Free</option>
                <option value="2">Borrowed</option>
              </Select>
              <Select color="black" placeholder="Sort by..." variant="filled" onChange={(e) => handleInputChange(e, setSortBy)}>
                <option value="title">Title</option>
                <option value="author">Author</option>
                <option value="year">Year</option>
                <option value="genreId">Genre</option>
                <option value="languageId">Language</option>
                <option value="statusId">Book status</option>
                <option value="createDate">Add date</option>
              </Select>
              <Select color="black" placeholder="Sort order..." variant="filled" onChange={(e) => handleInputChange(e, setSortOrder)}>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </Select>
              <Select color="black" placeholder="Per page..." variant="filled" onChange={(e) => handleInputChange(e, setPerPage)}>
                <option value="6">6</option>
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="36">36</option>
                <option value="48">48</option>
                <option value="60">60</option>
              </Select>
            </Stack>
          </Grid>
          <DrawerFooter>
            <Button
              variant="outline"
              marginRight="1%"
              _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
              bg="brand.black1"
              color="brand.white1"
              onClick={() => handleSubmit()}
            >
              Search
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default SearchBooks;
