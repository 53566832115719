import React from 'react';
import {
    Alert,
    AlertIcon,
    AlertDescription,
    Spinner
} from '@chakra-ui/core';

const SuccessAlert = ({ message, loader }) => {
    return (
        <>
            <Alert status="success">
                <AlertIcon />
                <AlertDescription>{message}</AlertDescription>
                {loader && <Spinner />}
            </Alert>
        </>
    );
};

export default SuccessAlert;