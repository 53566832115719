import React from 'react';
import {
  Spinner, Box
} from '@chakra-ui/core';

const Loader = () => {
  return (
    <Box style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100px',
      height: '100px',
      marginTop: '-50px',
      marginLeft: '-50px'
    }}>
      <Spinner size="xl" />
    </Box>
  )
}

export default Loader;