import React, { useState, useEffect } from 'react';
import { API_URL } from '../common/common.js';
import BookItem from '../components/BookItem/BookItem';
import UserListItem from '../components/UserListItem/UserListItem';
import { enumData } from '../validator/validator.js';
import {
  Grid,
  Box,
  Stack,
  Text,
} from '@chakra-ui/core';
import axios from 'axios';
import { useAuth } from '../context/auth';
import Pagination from '../components/Pagination/Pagination';
import Loader from '../components/Loader/Loader';
import ErrorAlert from '../components/ErrorAlert/ErrorAlert';

const Users = (props) => {

  const { sessionAuth } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(enumData.defaultPage);
  const [perPage, setPerPage] = useState(enumData.defaultPerPage);
  const [queryString, setQueryString] = useState(props.location.queryString);

  if (props.location.queryString && props.location.queryString !== queryString) {
    setQueryString(props.location.queryString);
    setPage(enumData.defaultPage);
  }

  let query = `?page=${page}`;
  queryString
    ? query += queryString
    : query += `&perPage=${perPage}`;

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/users/search${query}`, {
        headers: {
          Authorization: `Bearer ${sessionAuth.token}`,
        },
      })
      .then((res) => {
        setUsers(res.data.data)
        setTotalCount(res.data.totalCount);
        setPage(res.data.page);
        setPerPage(res.data.perPage);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [page, queryString]);

  return loading ? (
    <Loader />
  ) : error ? (
    <ErrorAlert status="error" error={error} />
  ) : (
        <>
          <Grid
            margin='1%'
            gap={2}
          >
            {users &&
              users.map((user) => (
                <UserListItem user={user} key={user.id} />
              ))}
          </Grid>
          <Stack isInline justify="center">
            <Text fontWeight="normal">Users found:</Text>
            <Text fontWeight="bold">{totalCount}</Text>
          </Stack>
          <Stack isInline justify="center">
            <Box>
              <Pagination totalCount={totalCount} page={page} perPage={perPage} setPage={(page) => setPage(page)} />
            </Box>
          </Stack>
          <br />
        </>
      );
};

export default Users;

