import { theme } from '@chakra-ui/core';

const breakpoints = ['360px', '768px', '1024px', '1440px'];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const customTheme = {
    ...theme,
    breakpoints,

    colors: {
        ...theme.colors,
        brand: {
            green1: '#43a747',
            green2: '#47db00',
            black1: '#020b16',
            white1: '#ffffff',
            white2: '#E2E8F0',
            red1: '#dd2211'
        },
    },
};
