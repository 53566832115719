import React, { useState, useEffect } from 'react';
import { API_URL } from '../common/common.js';
import Comment from '../components/Comment/Comment';
import {
  Spinner,
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
  Box,
  Grid,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/core';
import { useAuth } from '../context/auth';
import axios from 'axios';

const Comments = (props) => {
  const { sessionAuth } = useAuth();
  const [comments, setComments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const bookId = props.bookId;
  const reviewId = props.review.id;
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/books/${bookId}/reviews/${reviewId}/comments/`, {
        headers: {
          Authorization: `Bearer ${sessionAuth.token}`,
        },
      })
      .then((res) => setComments(res.data))
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  }, [bookId, reviewId]);

  return loading ? (
    <Spinner />
  ) : error ? (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>'Error: '</AlertTitle>
      <AlertDescription> {error} </AlertDescription>
    </Alert>
  ) : comments?.length ? (
    <Grid marginBottom="10px" templateColumns="repeat(1, 1fr)" gap={2}>
      <Accordion defaultIndex={[1]} allowMultiple>
        <AccordionItem>
          <AccordionHeader>
            <Box flex="1" textAlign="left">
              Comments
            </Box>
            <AccordionIcon />
          </AccordionHeader>
          <AccordionPanel pb={4}>
            {comments.map((comment) => (
              <Comment
                key={comment.id}
                comment={comment}
                reviewId={props.reviewId}
                bookId={props.bookId}
                review={props.review}
                userRecord={props.userRecord}
                triggerRefresh={props.triggerRefresh}
              />
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Grid>
  ) : null;
};

export default Comments;
