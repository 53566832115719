import { useState, useEffect } from 'react';
import { API_URL } from '../common/common.js';
import axios from 'axios';
import { useAuth } from '../context/auth';

const useUserData = (userId, trigger) => {
  const { sessionAuth } = useAuth();
  const [user, setUser] = useState({
    data: null,
    borrows: null,
    reviews: null,
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    if (!trigger) return;
    const authHeader = {
      headers: {
        Authorization: `Bearer ${sessionAuth.token}`,
      },
    };
    axios
      .all([
        axios.get(`${API_URL}/users/${userId}`, authHeader),
        axios.get(`${API_URL}/users/${userId}/borrows/`, authHeader),
        axios.get(`${API_URL}/users/${userId}/reviews/`, authHeader),
      ])
      .then(
        axios.spread((user, borrows, reviews) => {
          setUser({
            data: user.data,
            borrows: borrows.data,
            reviews: reviews.data,
            isLoading: false,
            error: null,
          });
        })
      )
      .catch((error) => {
        if (error.response) {
          setUser({
            data: null,
            borrows: null,
            reviews: null,
            isLoading: false,
            error: error.response.data.error,
          });
        }
      });
  }, [trigger]);

  return user;
};

export default useUserData;
