import React from 'react';
import bookmark from '../../assets/icons/bookmark.svg';
import { Image, Tooltip } from '@chakra-ui/core';

const Bookmark = ({ status }) => {
  return (
    <div style={{ width: '95%' }}>
      <Tooltip hasArrow label={status} placement="right">
        <Image
          marginTop="-0.50%"
          src={bookmark}
          alt={status}
          style={{
            filter: status === 'Borrowed' ? 'grayscale(100%)' : 'grayscale(0%)',
            float: 'right',
          }}
          className="bookmark"
        />
      </Tooltip>
    </div>
  );
};

export default Bookmark;
