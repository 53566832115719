import React from 'react';
import './BookItem.css';
import RatingStars from '../RatingStars/RatingStars';
import Bookmark from '../Bookmark/Bookmark';
import { API_URL } from '../../common/common.js';
import { Image, Tooltip } from '@chakra-ui/core';

const BookItem = ({ book }) => {
  return (
    <div className="BookItem">
      <div className="BookItem-body">
        <Tooltip hasArrow label={book.title} placement="bottom" zIndex="1000">
          <Image
            className="BookItem-cover"
            src={`${API_URL}/images/${book.cover}`}
            fallbackSrc={`${API_URL}/images/default_book.png`}
            alt="BookItem-cover"
          ></Image>
        </Tooltip>
        <Bookmark status={book.status} />
      </div>
      <RatingStars rating={book.rating} isHalf={true} edit={false}></RatingStars>
      <div className="BookItem-Info">
        <p className="title-name">{book.title}</p>
        <p className="author-name">
          {book.author} ({book.year})
        </p>
      </div>
    </div>
  );
};

export default BookItem;
