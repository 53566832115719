import React, { useState } from 'react';
import axios from 'axios';
import ErrorAlert from '../components/ErrorAlert/ErrorAlert';
import { API_URL } from '../common/common.js';
import { useAuth } from '../context/auth';
import {
  Button,
  Modal,
  useDisclosure,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Textarea,
  useToast,
  ModalCloseButton,
} from '@chakra-ui/core';
import validateParam from '../validator/validator';
import ErrorLabel from '../components/ErrorLabel/ErrorLabel';

const CreateCommentModal = ({ bookId, review, triggerRefresh }) => {
  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorClient, setErrorClient] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [comment, setComment] = useState('');

  const toast = useToast();

  const initialRef = React.useRef();
  const reviewId = review.id;

  const submitComment = (comment) => {    
    alert('This feature has been disabled!');
  };

  return (!sessionAuth.user.isBanned &&
    <>
      <Button
        style={{ cursor: 'pointer' }}
        _hover={{ color: 'brand.green1', bg: 'transparent' }}
        variantColor="brand.green1"
        variant="ghost"
        bg="transparent"
        onClick={onOpen}
      >
        Leave a comment
      </Button>
      <Modal
        initialFocusRef={initialRef}
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Comment</ModalHeader>
          <ModalBody>
            <div>{'Leave a comment to ' + review.userName}</div>
            <ModalCloseButton
              bg="brand.red1"
              color="brand.white1"
              _hover={{ bg: 'brand.black1', color: 'brand.white1' }}
            />
            {!loading ? error && <ErrorAlert error={error} /> : null}
            {!loading ? errorClient && <ErrorLabel message={errorClient} /> : null}

            <Textarea
              ref={initialRef}
              marginTop="2%"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></Textarea>
          </ModalBody>
          <ModalFooter>
            {!loading && (
              <Button
                marginRight="80%"
                _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                bg="brand.black1"
                color="brand.white1"
                onClick={() => submitComment(comment)}
              >
                Submit
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateCommentModal;
