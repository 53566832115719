import React from 'react';
import {
  Grid,
  Stack,
  Text,
  Box,
  PseudoBox,
  Tooltip
} from '@chakra-ui/core';
import useDashboardData from '../hooks/use-dashboard-data.js'
import BookItem from '../components/BookItem/BookItem';
import { NavLink } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import ErrorAlert from '../components/ErrorAlert/ErrorAlert';
import Like from '../components/Like/Like';
import RatingStars from '../components/RatingStars/RatingStars';
import UserAvatar from '../components/UserAvatar/UserAvatar';
import UserModal from '../components/UserModal/UserModal';
import { useAuth } from '../context/auth';

const Home = (props) => {
  const { sessionAuth } = useAuth();
  const dashboard = useDashboardData();

  return dashboard.isLoading ? (
    <Loader />
  ) : dashboard.error ? (
    <ErrorAlert status="error" error={dashboard.error} />
  ) : (

        <Grid
          justifyItems="center"
          alignItems="top"
          templateColumns={{
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: '70% 30%',
            xl: '70% 30%',
          }}
          gap={2}
          margin="1%"
        >
          <Box>
            <Text fontWeight="bold">Top books</Text>
            <Grid
              justifyItems="start"
              alignItems="top"
              templateColumns={{
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(3, 1fr)',
                xl: 'repeat(6, 1fr)',
              }}
              templateRows='auto'
              gap={2}
            >
              {dashboard && dashboard.data[0].map((book) => (
                <NavLink to={`/books/${book.id}`} key={book.id}>
                  <BookItem key={book.id} book={book} />
                </NavLink>
              ))
              }
            </Grid>
            <Text fontWeight="bold">New books</Text>
            <Grid
              justifyItems="start"
              alignItems="top"
              templateColumns={{
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(3, 1fr)',
                xl: 'repeat(6, 1fr)',
              }}
              gap={2}
            >
              {dashboard && dashboard.data[1].map((book) => (
                <NavLink to={`/books/${book.id}`} key={book.id}>
                  <BookItem key={book.id} book={book} />
                </NavLink>
              ))
              }
            </Grid>
          </Box>
          <Box>
            <Grid gap={2}>
              <Text fontWeight="bold">Top Reviews</Text>
              {dashboard.data[2].map((review) => {
                return (
                  <NavLink
                    to={`/books/${review.bookId}`}
                    key={review.id}
                  >
                    <PseudoBox as="div" bg="gray.100" padding="2%" _hover={{ bg: 'gray.200' }} width={{ xl: '400px', lg: '300px', md: '500px', sm: '350px' }}>
                      <Tooltip hasArrow label={`${review.bookTitle} by ${review.bookAuthor}`} placement="bottom" zIndex="1000">
                        <Stack isInline justifyContent="left" alignItems="flex-start" isTruncated>
                          <Text>
                            <PseudoBox as="span" fontSize="md" fontWeight="bold">
                              {review.bookTitle}&nbsp;
                      </PseudoBox>
                            <PseudoBox as="span">
                              by&nbsp;
                      </PseudoBox>
                            <PseudoBox as="span" fontSize="md" fontWeight="semibold" fontStyle="italic">
                              {review.bookAuthor}
                            </PseudoBox>
                          </Text>
                        </Stack>
                      </Tooltip>
                      <Stack isInline justifyContent="space-between">
                        <Box>
                          <RatingStars rating={review.rating} isHalf={false} edit={false} />
                        </Box>
                        <Box>
                          <Like likes={review.likes} />
                        </Box>
                      </Stack>
                      <Box>
                        <Tooltip hasArrow label={review.content} placement="bottom" zIndex="1000">
                          <Text fontSize="md" isTruncated>
                            {review.content}
                          </Text>
                        </Tooltip>
                      </Box>
                    </PseudoBox>
                  </NavLink>
                );
              })}
              <Box height={{ xl: '75px', lg: '35px', md: '0px', sm: '0px' }}></Box>
            </Grid>
            <Grid gap={2}>
              <Text fontWeight="bold">Top Users</Text>
              {dashboard.data[3].map((user, i) => {
                return (
                  <Stack isInline
                    justifyContent="space-between"
                    justifyItems="center"
                    aligntItems="center"
                    bg={i === 0 ? 'gray.500' : i === 1 ? 'gray.400' : i === 2 ? 'gray.300' : 'gray.100'}
                    padding="2%"
                    roundedLeft="full">
                    {sessionAuth
                      ?
                      <UserModal userId={user.id}>
                        <UserAvatar
                          userAvatar={user.avatar}
                          userName={user.username}
                          userLevel={user.level}
                        />
                      </UserModal>
                      :
                      <UserAvatar
                        userAvatar={user.avatar}
                        userName={user.username}
                        userLevel={user.level}
                      />
                    }
                    <Text fontWeight="semibold" marginRight="10px" marginTop="12px">{user.points}</Text>
                  </Stack>
                )
              })
              }
            </Grid>
          </Box>
        </Grid>
      );
};



export default Home;
