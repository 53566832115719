import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { API_URL } from '../../common/common.js';
import UploadImage from '../UploadImage/UploadImage';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import SelectGenre from '../SelectGenre/SelectGenre';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import axios from 'axios';
import useEnums from '../../hooks/use-enums.js';
import validateParam from '../../validator/validator';
import { Textarea, Box, Grid, Button, Input, useToast, Select } from '@chakra-ui/core';
import { useAuth } from '../../context/auth';
import ErrorLabel from '../ErrorLabel/ErrorLabel';
import { enumData } from '../../validator/validator';

const schema = { title: false, author: false, year: false, description: false };

const EditBook = withRouter(({ book, history }) => {
  const { sessionAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(() => schema);
  const [trigger, setTrigger] = useState(false);
  const [title, setTitle] = useState(book.title);
  const [author, setAuthor] = useState(book.author);
  const [year, setYear] = useState(book.year);
  const [description, setDescription] = useState(book.description);
  const [languageId, setLanguageId] = useState(book.languageId);
  const [genreId, setGenreId] = useState(book.genreId);
  const [statusId, setStatusId] = useState(book.statusId);
  const [cover, setCover] = useState(book.cover);
  const [newCover, setNewCover] = useState(book.cover);

  const toast = useToast();
  const enums = useEnums();

  const refreshPage = () => {
    history.replace({
      pathname: '/empty',
    });
    setTimeout(() => {
      history.replace(
        {
          pathname: '/admin/books',
        },
        0
      );
    });
  };

  const update = (data) => {
    
    alert('This feature has been disabled!');
  }

  const handleSubmit = () => {

    if (cover !== newCover) {
      setTrigger(!trigger);
    } else {
      update({
        title,
        author,
        year,
        cover,
        description,
        genreId,
        languageId,
        statusId,
      });
    }

  }

  useEffect(() => {
    if (!trigger) {
      return;
    }
    update({
      title,
      author,
      year,
      cover,
      description,
      genreId,
      languageId,
      statusId,
    });
  }, [cover]);

  const handleInputChange = (e, setter) => {
    const inputValue = e.target.value;
    const name = e.target.name;
    const error = validateParam(name, inputValue);
    const errorObj = { ...validationError };
    setter(inputValue);
    if (error) {
      errorObj[name] = error;
      return setValidationError(errorObj);
    } else {
      errorObj[name] = null;
      return setValidationError(errorObj);
    }
  };
  const isDisabled = !Object.values(validationError).every((v) => !v);
  return (
    <>
      {!loading || !enums.isLoading
        ? (error || enums.error) && <ErrorAlert error={error || enums.error} />
        : null}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Grid margin="3%" templateColumns="70% 30%" width="600px" gap={6}>
          <Box p={5} shadow="md" borderWidth="1px">
            <Input
              name="title"
              placeholder="Title"
              value={title}
              onChange={(e) => handleInputChange(e, setTitle)}
            />
            {validationError['title'] ? (
              <ErrorLabel message={validationError['title']}></ErrorLabel>
            ) : (
                null
              )}
            <Input
              name="author"
              placeholder="Author"
              value={author}
              onChange={(e) => handleInputChange(e, setAuthor)}
            />
            {validationError['author'] ? (
              <ErrorLabel message={validationError['author']}></ErrorLabel>
            ) : (
                null
              )}
            <Input
              name="year"
              placeholder="Year"
              value={year}
              onChange={(e) => handleInputChange(e, setYear)}
            />
            {validationError['year'] ? (
              <ErrorLabel message={validationError['year']}></ErrorLabel>
            ) : (
                null
              )}
            <Textarea
              name="description"
              value={description}
              onChange={(e) => handleInputChange(e, setDescription)}
              placeholder="Description"
              resize="none"
            />
            {validationError['description'] ? (
              <ErrorLabel message={validationError['description']}></ErrorLabel>
            ) : (
                null
              )}
            <SelectLanguage
              enums={enums}
              value={book.languageId}
              onChange={(e) => handleInputChange(e, setLanguageId)}
            />
            <SelectGenre
              enums={enums}
              value={book.genreId}
              onChange={(e) => handleInputChange(e, setGenreId)}
            />
            <Select color="black" value={book.statusId} variant="flushed" onChange={(e) => handleInputChange(e, setStatusId)}>
              <option value="1">Free</option>
              <option value="2">Borrowed</option>
            </Select>
          </Box>
          <Box p={5}>
            <UploadImage
              defaultImage={`${API_URL}/images/` + cover}
              type={'cover'}
              trigger={trigger}
              setter={setCover}
              setterNew={setNewCover}
            ></UploadImage>
          </Box>
          <Button
            _hover={{ bg: isDisabled ? 'brand.red1' : 'brand.green2', color: isDisabled ? 'brand.white1' : 'brand.black1' }}
            bg="brand.black1"
            color="brand.white1"
            onClick={() => {
              handleSubmit();
            }}
            disabled={isDisabled}
            maxWidth="100px"
          >
            Submit
          </Button>
        </Grid>
      </Box>
    </>
  );
})

export default EditBook;
