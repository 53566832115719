import { useState, useEffect } from 'react';
import { API_URL } from '../common/common.js';
import axios from 'axios';
import { useAuth } from '../context/auth';

const useEnums = () => {
    const { sessionAuth } = useAuth();
    const [enums, setEnums] = useState({
        data: null,
        isLoading: true,
        error: null,
    });

    useEffect(() => {
        axios
            .get(`${API_URL}/enums`, {
                headers: {
                    Authorization: `Bearer ${sessionAuth?.token}`,
                },
            })
            .then((res) => {
                setEnums({
                    data: res.data,
                    isLoading: false,
                    error: null,
                });
            })
            .catch((error) => {
                if (error.response) {
                    setEnums({
                        data: null,
                        isLoading: false,
                        error: error.response.data.error,
                    });
                }
            })
    }, []);

    return enums;
};

export default useEnums;