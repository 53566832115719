import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
} from '@chakra-ui/core';

const BorrowAlertBox = ({ setAction, actionName, title }) => {
  const cancelRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        marginRight="7px"
        _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
        bg="brand.black1"
        color="brand.white1"
      >
        {actionName}
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {actionName} Book
          </AlertDialogHeader>
          <AlertDialogBody>
            <div> {`Are you sure you want to ${actionName} `}</div>
            <div style={{ fontWeight: 900 }}> {`"${title}"?`}</div>
            {actionName === 'Borrow' && <div> {`Please return the Book in two weeks tops.`}</div>}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={() => {
                setAction();
                onClose();
              }}
              ml='7px'
              marginRight="7px"
              _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
              bg="brand.black1"
              color="brand.white1"
            >
              {actionName}
            </Button>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
export default BorrowAlertBox;
