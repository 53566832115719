export const enumData = {
  minId: 1,
  minPage: 1,
  minRating: 1,
  maxRating: 5,
  minDays: 1,
  defaultPage: 1,
  defaultPerPage: 12,
  defaultSortBy: 'title',
  defaultSortOrder: 'asc',
  sortByValues: new Set(['title', 'author', 'year', 'createDate', 'genreId', 'languageId', 'statusId']),
  sortOrderValues: new Set(['asc', 'desc']),
  defaultSortUserBy: 'username',
  sortByUserValues: new Set(['username', 'createDate', 'points', 'level', 'isAdmin', 'isBanned']),
  userNameMinLength: 3,
  userNameMaxLength: 25,
  passwordMinLength: 3,
  passwordMaxLength: 25,
  titleMinLength: 3,
  titleMaxLength: 100,
  authorMinLength: 3,
  authorMaxLength: 100,
  reviewMinLength: 5,
  reviewMaxLength: 2000,
  commentMinLength: 5,
  commentMaxLength: 2000,
  reasonMinLength: 5,
  reasonMaxLength: 60,
  descriptionMinLength: 15,
  descriptionMaxLength: 2000,

  toastDuration: 5000,
};

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
};

const predicate = (value, errorMsg, ...callbacks) => !callbacks.every((fn) => fn(value)) ? errorMsg : null;

const validateParam = (type, value) => {
  switch (type) {
    case 'username':
      return predicate(
        value,
        `Username should be ${enumData.userNameMinLength} - ${enumData.userNameMaxLength} characters long`,
        (username) => username,
        (username) => typeof username === 'string',
        (username) => username.trim(),
        (username) => username.trim().length >= enumData.userNameMinLength,
        (username) => username.trim().length <= enumData.userNameMaxLength
      );
    case 'password':
      return predicate(
        value,
        `Password should be ${enumData.passwordMinLength} - ${enumData.passwordMaxLength} characters long`,
        (password) => password,
        (password) => typeof password === 'string',
        (password) => password.trim(),
        (password) => password.trim().length >= enumData.passwordMinLength,
        (password) => password.trim().length <= enumData.passwordMaxLength
      );

    case 'email':
      return predicate(value, 'Email should be a valid email', (email) =>
        validateEmail(email)
      );
    case 'comment':
      return predicate(
        value,
        `Comment should be ${enumData.commentMinLength} - ${enumData.commentMaxLength} characters long`,
        (comment) => comment,
        (comment) => typeof comment === 'string',
        (comment) => comment.trim(),
        (comment) => comment.trim().length >= enumData.commentMinLength,
        (comment) => comment.trim().length <= enumData.commentMaxLength
      );
    case 'reason':
      return predicate(
        value,
        ` should be ${enumData.reasonMinLength} - ${enumData.reasonMaxLength} characters long`,
        (reason) => reason,
        (reason) => typeof reason === 'string',
        (reason) => reason.trim(),
        (reason) => reason.trim().length >= enumData.reasonMinLength,
        (reason) => reason.trim().length <= enumData.reasonMaxLength
      );
    case 'review':
      return predicate(
        value,
        `Review should be ${enumData.reviewMinLength} - ${enumData.reviewMaxLength} characters long`,
        (review) => review,
        (review) => typeof review === 'string',
        (review) => review.trim(),
        (review) => review.trim().length >= enumData.reviewMinLength,
        (review) => review.trim().length <= enumData.reviewMaxLength
      );
    case 'rating':
      return predicate(
        value,
        `Please, select rating stars.`,
        (rating) => typeof +rating === 'number',
        (rating) => Number.isInteger(+rating),
        (rating) => rating >= enumData.minRating && rating <= enumData.maxRating
      );
    case 'title':
      return predicate(
        value,
        `Book title should be ${enumData.titleMinLength} - ${enumData.titleMaxLength} characters long`,
        (title) => title,
        (title) => typeof title === 'string',
        (title) => title.trim(),
        (title) => title.trim().length >= enumData.titleMinLength,
        (title) => title.trim().length <= enumData.titleMaxLength
      );
    case 'author':
      return predicate(
        value,
        `Author name should be ${enumData.authorMinLength} - ${enumData.authorMaxLength} characters long`,
        (author) => author,
        (author) => typeof author === 'string',
        (author) => author.trim(),
        (author) => author.trim().length >= enumData.authorMinLength,
        (author) => author.trim().length <= enumData.authorMaxLength
      );
    case 'year':
      return predicate(
        value,
        'Year should be a valid integer',
        (year) => year,
        (year) => year.trim(),
        (year) => typeof +year === 'number',
        (year) => Number.isInteger(+year)
      );

    case 'description':
      return predicate(
        value,
        `Book description should be ${enumData.descriptionMinLength} - ${enumData.descriptionMaxLength} characters long`,
        (description) => description,
        (description) => typeof description === 'string',
        (description) => description.trim(),
        (description) => description.length >= enumData.descriptionMinLength,
        (description) => description.length <= enumData.descriptionMaxLength
      );

    case 'languageId':
      return predicate(
        value,
        `Please pick a language`,
        (languageId) => typeof +languageId === 'number',
        (languageId) => Number.isInteger(+languageId),
        (languageId) => +languageId >= enumData.minId,
        (languageId) => enumData.languages.find(item => +item.id === +languageId));

    case 'genreId':
      return predicate(
        value,
        `Please pick a genre`,
        (genreId) => typeof +genreId === 'number',
        (genreId) => Number.isInteger(+genreId),
        (genreId) => +genreId >= enumData.minId,
        (genreId) => enumData.genres.find(item => +item.id === +genreId));

    default:
      break;
  }
};

export default validateParam;
