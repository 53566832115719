import React, { useState } from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  TabList,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  Grid,
  Box,
  PseudoBox,
  Stack,
  Tooltip
} from '@chakra-ui/core';
import useUserData from '../../hooks/user-data';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import Loader from '../Loader/Loader';
import UserAvatar from '../UserAvatar/UserAvatar';
import DateView from '../DateView/DateView';
import Like from '../Like/Like';
import RatingStars from '../RatingStars/RatingStars';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import UserProfile from '../UserProfile/UserProfile';
import { HashLink } from 'react-router-hash-link';

const UserModal = (props) => {
  const { sessionAuth } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showEdit, setShowEdit] = useState(false);

  const userData = useUserData(props.userId, isOpen);
  const user = userData.data;
  const isLoggedUser = props.userId === sessionAuth.user.id;
  const email = isLoggedUser ? sessionAuth?.user.email : user?.email;

  return (
    <>
      <Box onClick={onOpen} style={{ cursor: 'pointer', maxWidth: '26.5%' }}>
        {props.children}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          {userData.isLoading ? (
            <Loader />
          ) : userData.error ? (
            <ErrorAlert error={userData.error} />
          ) : (
                <>
                  <ModalBody>
                    <Tabs>
                      <TabList>
                        <Tab _selected={{ color: 'white', bg: 'brand.green1' }}>
                          User
                    </Tab>
                        <Tab _selected={{ color: 'white', bg: 'brand.green1' }}>
                          Books
                    </Tab>
                        <Tab _selected={{ color: 'white', bg: 'brand.green1' }}>
                          Reviews
                    </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel height="300px">
                          <br />
                          {showEdit ? <UserProfile selfClose={() => setShowEdit(!showEdit)} /> : (
                            <>
                              <Stack isInline justifyContent="space-between">
                                <UserAvatar
                                  userAvatar={isLoggedUser ? sessionAuth.user.avatar : user.avatar}
                                  userName={user.username}
                                  userLevel={user.level}
                                  avatarSize="xl"
                                />
                                <br />
                                {props.userId === sessionAuth.user.id &&
                                  <Button
                                    size="xs"
                                    mr={3}
                                    _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                                    bg="brand.black1"
                                    color="brand.white1"
                                    onClick={() => alert('This feature has been disabled!')}
                                  >
                                    Edit Profile
                                  </Button>
                                }
                              </Stack>
                              {!!userData.data.isBanned ?
                                <PseudoBox color="red.500" as="span" fontWeight="semibold">
                                  <Stack isInline>
                                    <Text fontSize="md" fontWeight="bold">Banned</Text>
                                    <Text fontSize="md" fontStyle="italic"> until  <DateView date={user.banToDate} /></Text>
                                  </Stack>
                                  {user.banDescription &&
                                    <Stack isInline>
                                      <Text fontSize="md">Reason: </Text>
                                      <Text fontSize="md" fontStyle="italic">{user.banDescription}</Text>
                                    </Stack>}
                                </PseudoBox>
                                : <br />}
                              <Stack isInline>
                                <Text mb="1rem" fontWeight="bold">
                                  Email:
                                </Text>
                                <Text mb="1rem">
                                  {email ? email : '<None>'}
                                </Text>
                              </Stack>
                              <Stack isInline>
                                <Text fontWeight="bold" mb="1rem">
                                  Points:
                                </Text>
                                <Text mb="1rem">
                                  {user.points}
                                </Text>
                              </Stack>
                              <Stack isInline>
                                <Text fontWeight="bold" mb="1rem">
                                  Member since:
                                </Text>
                                <DateView date={user.createDate} />
                              </Stack>
                              <Stack isInline>
                                <Text fontWeight="bold" mb="1rem">
                                  Last seen:
                                </Text>
                                {user.lastSeen ? <DateView date={user.lastSeen} /> : <Box>Never</Box>}
                              </Stack>
                            </>)}
                        </TabPanel>
                        <TabPanel height="300px" overflowY="scroll;">
                          <br />
                          <Grid rowGap={2} >
                            {userData.borrows.length
                              ?
                              userData.borrows.map((borrow) => {
                                const borrowBookReview = userData.reviews.find((r) => r.bookId === borrow.bookId);
                                return (
                                  <Tooltip hasArrow
                                    label={
                                      borrow.toDate && borrowBookReview ? 'Reviewed' :
                                        borrow.toDate && !borrowBookReview ? 'Please, give a review' : 'In possession'}
                                    _hover={{ bg: 'gray.200' }}
                                    placement="bottom"
                                    zIndex="10000"
                                    key={borrow.id}>
                                    <NavLink
                                      to={`/books/${borrow.bookId}`}
                                    >
                                      <PseudoBox as="div" bg={
                                        borrow.toDate && borrowBookReview ? 'green.100' :
                                          borrow.toDate && !borrowBookReview ? 'yellow.100' : 'red.100'}
                                        padding="2%" _hover={{ bg: 'gray.200' }}>
                                        <Stack isInline justifyContent="left" alignItems="flex-start">
                                          <Text>
                                            <PseudoBox as="span" fontSize="md" fontWeight="bold">
                                              {borrow.bookTitle}&nbsp;
                                          </PseudoBox>
                                            <PseudoBox as="span">
                                              by&nbsp;
                                          </PseudoBox>
                                            <PseudoBox as="span" fontSize="md" fontWeight="semibold" fontStyle="italic">
                                              {borrow.bookAuthor}
                                            </PseudoBox>
                                          </Text>
                                        </Stack>
                                      </PseudoBox>
                                    </NavLink>
                                  </Tooltip>
                                );
                              })
                              :
                              <PseudoBox as="span" fontSize="md" fontWeight="semibold" fontStyle="italic">
                                No read books yet.
                              </PseudoBox>
                            }
                          </Grid>

                        </TabPanel>
                        <TabPanel height="300px" overflow="auto">
                          <br />
                          <Grid rowGap={2} >
                            {userData.reviews.length
                              ?
                              userData.reviews.map((review) => {
                                return (
                                  review.bookTitle /* If book is deleted, don't draw a review line */
                                    ?
                                    <HashLink
                                      to={`/books/${review.bookId}#r${review.id}`} /* Go to #review in list, use HashLink because of bug in NavLink */
                                      key={review.id}
                                    >
                                      <PseudoBox as="div" bg="gray.100" padding="2%" _hover={{ bg: 'gray.200' }} width="400px">
                                        <Stack isInline justifyContent="left" alignItems="flex-start">
                                          <Text>
                                            <PseudoBox as="span" fontSize="md" fontWeight="bold">
                                              {review.bookTitle}&nbsp;
                                        </PseudoBox>
                                            <PseudoBox as="span">
                                              by&nbsp;
                                        </PseudoBox>
                                            <PseudoBox as="span" fontSize="md" fontWeight="semibold" fontStyle="italic">
                                              {review.bookAuthor}
                                            </PseudoBox>
                                          </Text>
                                        </Stack>
                                        <Stack isInline justifyContent="space-between">
                                          <Box>
                                            <RatingStars rating={review.rating} isHalf={false} edit={false} />
                                          </Box>
                                          <Box>
                                            <Like likes={review.likes} />
                                          </Box>
                                        </Stack>
                                        <Box>
                                          <Tooltip hasArrow label={review.content} placement="bottom" zIndex="10000">
                                            <Text fontSize="md" isTruncated>
                                              {review.content}
                                            </Text>
                                          </Tooltip>
                                        </Box>
                                      </PseudoBox>
                                    </HashLink>
                                    : null
                                );
                              })
                              :
                              <PseudoBox as="span" fontSize="md" fontWeight="semibold" fontStyle="italic">
                                No book reviews yet.
                              </PseudoBox>
                            }
                          </Grid>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variantColor="blue"
                      mr={3}
                      onClick={onClose}
                      _hover={{ bg: 'brand.green2', color: 'brand.black1' }}
                      bg="brand.black1"
                      color="brand.white1"
                    >
                      Close
                  </Button>
                  </ModalFooter>
                </>
              )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserModal;
