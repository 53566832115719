import React from 'react';
import { PseudoBox } from '@chakra-ui/core';

const DateView = ({ date, size }) => {
  return (
    <PseudoBox as="span" fontSize={size}>
      {new Date(date).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}
    </PseudoBox>
  );
};
export default DateView;
